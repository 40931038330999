app.controller('eindschermCtrl', ['$scope', '$sce', '$location', 'mainService', '$rootScope', function($scope, $sce, $location, mainService, $rootScope, Api, $route, $routeParams) {
    $rootScope._showMenu = false;
    $scope.sendCommand = function() {
        console.log("eindschermCtrl");
    };

    $scope.go = function(path) {
        console.log("Hello World", path)
        $location.path(path);

    };
}]);