app.controller('opdrachtCtrl_1', ['mainService', '$scope', '$sce', '$location', '$rootScope', function(mainService, $scope, $sce, $location, $rootScope, Api, $route, $routeParams) {
    $scope._optionData = {};
    $scope._optionData = mainService.optiondata;

    $scope.vidSrc_1_1 = $sce.trustAsResourceUrl(mainService.vidSrc1_Op1_1);
    $scope.vidSrc_1_2 = $sce.trustAsResourceUrl(mainService.vidSrc1_Op1_2);
    $scope.vidSrc_1_3 = $sce.trustAsResourceUrl(mainService.vidSrc1_Op1_3);
    $scope.vidSrc_1_4 = $sce.trustAsResourceUrl(mainService.vidSrc1_Op1_4);
    $scope.vidSrc_1_5 = $sce.trustAsResourceUrl(mainService.vidSrc1_Op1_5);
    $scope.vidSrc_1_6 = $sce.trustAsResourceUrl(mainService.vidSrc1_Op1_6);

    $scope.vidSrc_2_1 = $sce.trustAsResourceUrl(mainService.vidSrc2_Op1_1);
    $scope.vidSrc_2_2 = $sce.trustAsResourceUrl(mainService.vidSrc2_Op1_2);
    $scope.vidSrc_2_3 = $sce.trustAsResourceUrl(mainService.vidSrc2_Op1_3);
    $scope.vidSrc_2_4 = $sce.trustAsResourceUrl(mainService.vidSrc2_Op1_4);
    $scope.vidSrc_2_5 = $sce.trustAsResourceUrl(mainService.vidSrc2_Op1_5);
    $scope.vidSrc_2_6 = $sce.trustAsResourceUrl(mainService.vidSrc2_Op1_6);

    $scope._blobs = [1, 2, 3, 4];

    $rootScope._selectedRow;
    $rootScope._selectedCirkel;

    // $scope.selectedTheory;


    // DEZE AAN
    $scope._displayState = $rootScope.opdr1state;



    // $scope._displayState = '0';


    // DEZE UIT
    // $rootScope.opdr1StateR = '0';

    $scope.changeState = function(state) {

        $rootScope.opdr1StateR = state;
        console.log($rootScope.opdr1state);
        // console.log("HueHUE")
        // return;
    };

    // $rootScope.opdr1StateR = '0';
    $scope.goPrev = function(path) {
        $rootScope.opdr1StateR = '-1';
        $rootScope.opdr2StateR = '-1';
        $rootScope.opdr3StateR = '-1';
        $rootScope.opdr4StateR = '-1';
        $location.path(path);
    };
    $scope.goNext = function(path) {
        $rootScope.opdr1StateR = '-1';
        $rootScope.opdr2StateR = '0';
        $rootScope.opdr3StateR = '-1';
        $rootScope.opdr4StateR = '-1';
        $location.path(path);
    };

    $scope.selectCirkel = function(rowNumber, indexNumber) {
        $rootScope.setMenu = true;
        console.log(rowNumber, indexNumber);
        $rootScope._selectedRow = rowNumber;
        $rootScope._selectedCirkel = indexNumber;
        switch (rowNumber) {
            case 1:
                {
                    switch (indexNumber) {
                        case 0:
                            {
                                console.log("1");
                                $scope.selectedTheory = $scope._optionData.row1.item1.title;
                                break;
                            }
                        case 1:
                            {
                                console.log("2");
                                $scope.selectedTheory = $scope._optionData.row1.item2.title;
                                break;
                            }
                        case 2:
                            {
                                console.log("3");
                                $scope.selectedTheory = $scope._optionData.row1.item3.title;
                                break;
                            }
                    }
                    break;
                }
            case 2:
                {
                    switch (indexNumber) {
                        case 0:
                            {
                                $scope.selectedTheory = $scope._optionData.row2.item1.title;
                                break;
                            }
                        case 1:
                            {
                                $scope.selectedTheory = $scope._optionData.row2.item2.title;
                                break;
                            }
                        case 2:
                            {
                                $scope.selectedTheory = $scope._optionData.row2.item3.title;
                                break;
                            }
                    }
                    break;
                }
            case 3:
                {
                    switch (indexNumber) {
                        case 0:
                            {
                                $scope.selectedTheory = $scope._optionData.row3.item1.title;
                                break;
                            }
                        case 1:
                            {
                                $scope.selectedTheory = $scope._optionData.row3.item2.title;
                                break;
                            }
                        case 2:
                            {
                                break;
                            }
                    }
                    break;
                }
        }
    }
}]);