app.config(function($routeProvider, $locationProvider) {

    $routeProvider
    // .when('/', {
    // 	controller: 'mainCtrl',
    // 	templateUrl: '/views/index.html',
    // })
        .when('/introductie', {
            url: '/introductie',
            controller: 'introductieCtrl',
            templateUrl: '/views/exhibit/introductie.html',

        })
        .when('/opdracht_1', {
            url: '/opdracht_1',
            controller: 'opdrachtCtrl_1',
            templateUrl: '/views/exhibit/opdracht_1.html',

        })
        .when('/opdracht_2', {
            controller: 'opdrachtCtrl_2',
            templateUrl: '/views/exhibit/opdracht_2.html',

        })
        .when('/opdracht_3', {
            controller: 'opdrachtCtrl_3',
            templateUrl: '/views/exhibit/opdracht_3.html',

        })
        .when('/opdracht_4', {
            controller: 'opdrachtCtrl_4',
            templateUrl: '/views/exhibit/opdracht_4.html',

        })
        .when('/eindscherm', {
            controller: 'eindschermCtrl',
            templateUrl: '/views/exhibit/eindscherm.html',

        })

    .otherwise({
        redirectTo: '/introductie'
    });

    // function CheckAuth($q, $rootScope){
    // 	return true;
    // 	// var defer = $q.defer();
    // 	// if(typeof $rootScope.user != 'undefined')
    // 	// {
    // 	// 	defer.resolve();
    // 	// }
    // 	// else{
    // 	// 	defer.reject("not_logged_in");
    // 	// }
    // 	// return defer.promise;
    // }
});