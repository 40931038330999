app.service("Api",function($http){

    var json = require("../config/settings.json");
    // console.log(json.baseApiUrl);

   var baseApiUrl = json.baseApiUrl
//    var baseApiUrl = "http://preview.kissthefrog.nl/na/";
   this.Get = function(url,callback,error){
       $http.get(baseApiUrl + url).then(function(res){
           console.log('Success',res);
           callback(res);
       },function(err){
           error(err);
           console.log('Error',err);
       });
   }
   this.Post = function(url,data,callback,error){
       $http.post(baseApiUrl + url,data).then(function(res){
           console.log('Success',res);
           callback(res);
       },function(err){
           console.log('Error',err);
           error(err);
       });
   }
    this.Put = function(url,data,callback,error){
       $http.put(baseApiUrl + url,data).then(function(res){
           console.log('Success',res);
           callback(res);
       },function(err){
           console.log('Error',err);
           error(err);
       });
   }
   
   this.Delete = function(url,data,callback,error){
       $http.delete(baseApiUrl + url,data).then(function(res){
           console.log('Success',res);
           callback(res);
       },function(err){
           console.log('Error',err);
           error(err);
       });
   }
});