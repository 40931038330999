(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/index.html',
    '<!--<!doctype html>-->\n' +
    '\n' +
    '<html lang="en" data-framework="angularjs">\n' +
    '\n' +
    '<head>\n' +
    '    <!--<base href="/smartboard/">-->\n' +
    '    <base href="/">\n' +
    '    <meta charset="utf-8">\n' +
    '    <title>Teylers Smartboard</title>\n' +
    '\n' +
    '    <!-- <script src="ngDraggable.js"></script> -->\n' +
    '    <link rel="shortcut icon" type="image/png" href="/assets/favicon.png" />\n' +
    '    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">\n' +
    '    <link rel="stylesheet" href="css/app.css">\n' +
    '    <script src="http://code.jquery.com/jquery-1.7.2.min.js"></script>\n' +
    '    <script src="http://code.jquery.com/ui/1.8.21/jquery-ui.min.js"></script>\n' +
    '    <!--<script src="https://hammerjs.github.io/dist/hammer.js"></script>-->\n' +
    '\n' +
    '</head>\n' +
    '<div ng-view></div>\n' +
    '\n' +
    '<body class="teylers-smartboard" ng-app="teylers-smartboard" ng-controller="mainCtrl">\n' +
    '    <div class="nav-menu">\n' +
    '        <div class="nav-show" ng-click="menuFunction(\'open\')" ng-if="!_showMenu"><i class="material-icons">menu</i></div>\n' +
    '        <div class="dev-nav" ng-if="_showMenu">\n' +
    '            <div class="nav-content">\n' +
    '                <!--<div class=""><a href="/#!/introductie">Introductie (dev)</a></div>-->\n' +
    '                <!--<div class=""><a href="/#!/eindscherm">Eindscherm (dev)</a></div>-->\n' +
    '                <div class="nav-click"><a ng-click="goToState()">HOME</a></div>\n' +
    '                <div class="nav-click"><a ng-click="changeState(\'opdr1\',\'0\', \'/opdracht_1\')">ELEKTRISCHE VERSCHIJNSELEN</a>\n' +
    '                    <!--<div class="sub-tabs">\n' +
    '                        <div class="sub-tab-grey" ng-if="opdr1StateR != \'0\'" ng-click="changeState(\'opdr1\',\'0\', \'/opdracht_1\')">Stap 1</div>\n' +
    '                        <div class="sub-tab-white" ng-if="opdr1StateR == \'0\'" ng-click="changeState(\'opdr1\',\'0\', \'/opdracht_1\')">Stap 1</div>\n' +
    '                        <div class="sub-tab-grey" ng-if="opdr1StateR != \'1\'" ng-click="changeState(\'opdr1\',\'1\', \'/opdracht_1\')">Stap 2</div>\n' +
    '                        <div class="sub-tab-white" ng-if="opdr1StateR == \'1\'" ng-click="changeState(\'opdr1\',\'1\', \'/opdracht_1\')">Stap 2</div>\n' +
    '                        <div ng-show="!setMenu">\n' +
    '                            <div class="sub-tab-grey-grey" ng-if="opdr1StateR != \'2\'">Stap 3</div>\n' +
    '                            <div class="sub-tab-white" ng-if="opdr1StateR == \'2\'" ng-click="changeState(\'opdr1\',\'2\', \'/opdracht_1\')">Stap 3</div>\n' +
    '                            <div class="sub-tab-grey-grey" ng-if="opdr1StateR != \'3\'">Stap 4</div>\n' +
    '                            <div class="sub-tab-white" ng-if="opdr1StateR == \'3\'" ng-click="changeState(\'opdr1\',\'3\', \'/opdracht_1\')">Stap 4</div>\n' +
    '                        </div>\n' +
    '                        <div ng-show="setMenu">\n' +
    '                            <div class="sub-tab-grey" ng-if="opdr1StateR != \'2\'" ng-click="changeState(\'opdr1\',\'2\', \'/opdracht_1\')">Stap 3</div>\n' +
    '                            <div class="sub-tab-white" ng-if="opdr1StateR == \'2\'" ng-click="changeState(\'opdr1\',\'2\', \'/opdracht_1\')">Stap 3</div>\n' +
    '                            <div class="sub-tab-grey" ng-if="opdr1StateR != \'3\'" ng-click="changeState(\'opdr1\',\'3\', \'/opdracht_1\')">Stap 4</div>\n' +
    '                            <div class="sub-tab-white" ng-if="opdr1StateR == \'3\'" ng-click="changeState(\'opdr1\',\'3\', \'/opdracht_1\')">Stap 4</div>\n' +
    '                        </div>\n' +
    '                    </div>-->\n' +
    '                </div>\n' +
    '                <div class="nav-click"><a ng-click="changeState(\'opdr2\',\'0\', \'/opdracht_2\')">ONTDEKKINGEN</a>\n' +
    '                    <!--<div class="sub-tabs">\n' +
    '                        <div class="sub-tab-grey" ng-if="opdr2StateR != \'0\'" ng-click="changeState(\'opdr2\',\'0\', \'/opdracht_2\')">Stap 1</div>\n' +
    '                        <div class="sub-tab-white" ng-if="opdr2StateR == \'0\'" ng-click="changeState(\'opdr2\',\'0\', \'/opdracht_2\')">Stap 1</div>\n' +
    '                        <div class="sub-tab-grey" ng-if="opdr2StateR != \'1\'" ng-click="changeState(\'opdr2\',\'1\', \'/opdracht_2\')">Stap 2</div>\n' +
    '                        <div class="sub-tab-white" ng-if="opdr2StateR == \'1\'" ng-click="changeState(\'opdr2\',\'1\', \'/opdracht_2\')">Stap 2</div>\n' +
    '                        <div ng-if="!setMenu2">\n' +
    '                            <div class="sub-tab-grey-grey" ng-if="opdr2StateR != \'2\'">Stap 3</div>\n' +
    '                            <div class="sub-tab-white" ng-if="opdr2StateR == \'2\'" ng-click="changeState(\'opdr2\',\'2\', \'/opdracht_2\')">Stap 3</div>\n' +
    '                            <div class="sub-tab-grey-grey" ng-if="opdr2StateR != \'3\'">Stap 4</div>\n' +
    '                            <div class="sub-tab-white" ng-if="opdr2StateR == \'3\'" ng-click="changeState(\'opdr2\',\'3\', \'/opdracht_2\')">Stap 4</div>\n' +
    '                        </div>\n' +
    '                        <div ng-if="setMenu2">\n' +
    '                            <div class="sub-tab-grey" ng-if="opdr2StateR != \'2\'" ng-click="changeState(\'opdr2\',\'2\', \'/opdracht_2\')">Stap 3</div>\n' +
    '                            <div class="sub-tab-white" ng-if="opdr2StateR == \'2\'" ng-click="changeState(\'opdr2\',\'2\', \'/opdracht_2\')">Stap 3</div>\n' +
    '                            <div class="sub-tab-grey" ng-if="opdr2StateR != \'3\'" ng-click="changeState(\'opdr2\',\'3\', \'/opdracht_2\')">Stap 4</div>\n' +
    '                            <div class="sub-tab-white" ng-if="opdr2StateR == \'3\'" ng-click="changeState(\'opdr2\',\'3\', \'/opdracht_2\')">Stap 4</div>\n' +
    '                        </div>\n' +
    '                    </div>-->\n' +
    '                </div>\n' +
    '                <div class="nav-click"><a ng-click="changeState(\'opdr3\',\'0\', \'/opdracht_3\')">WETENSCHAPPERS</a>\n' +
    '                    <div class="sub-tabs">\n' +
    '                        <!--<div class="sub-tab-grey" ng-if="opdr3StateR != \'0\'" ng-click="changeState(\'opdr3\',\'0\', \'/opdracht_3\')">Stap 1</div>\n' +
    '                        <div class="sub-tab-white" ng-if="opdr3StateR == \'0\'" ng-click="changeState(\'opdr3\',\'0\', \'/opdracht_3\')">Stap 1</div>\n' +
    '                        <div class="sub-tab-grey" ng-if="opdr3StateR != \'1\'" ng-click="changeState(\'opdr3\',\'1\', \'/opdracht_3\')">Stap 2</div>\n' +
    '                        <div class="sub-tab-white" ng-if="opdr3StateR == \'1\'" ng-click="changeState(\'opdr3\',\'1\', \'/opdracht_3\')">Stap 2</div>\n' +
    '                        <div ng-if="!setMenu3">\n' +
    '                            <div class="sub-tab-grey-grey" ng-if="opdr3StateR != \'2\'">Stap 3</div>\n' +
    '                            <div class="sub-tab-white" ng-if="opdr3StateR == \'2\'" ng-click="changeState(\'opdr3\',\'2\', \'/opdracht_3\')">Stap 3</div>\n' +
    '                            <div class="sub-tab-grey-grey" ng-if="opdr3StateR != \'3\'">Stap 4</div>\n' +
    '                            <div class="sub-tab-white" ng-if="opdr3StateR == \'3\'" ng-click="changeState(\'opdr3\',\'3\', \'/opdracht_3\')">Stap 4</div>\n' +
    '                            <div class="sub-tab-grey-grey" ng-if="opdr3StateR != \'4\'">Stap 5</div>\n' +
    '                            <div class="sub-tab-white" ng-if="opdr3StateR == \'4\'" ng-click="changeState(\'opdr3\',\'4\', \'/opdracht_3\')">Stap 5</div>\n' +
    '                        </div>\n' +
    '                        <div ng-if="setMenu3">\n' +
    '                            <div class="sub-tab-grey" ng-if="opdr3StateR != \'2\'" ng-click="changeState(\'opdr3\',\'2\', \'/opdracht_3\')">Stap 3</div>\n' +
    '                            <div class="sub-tab-white" ng-if="opdr3StateR == \'2\'" ng-click="changeState(\'opdr3\',\'2\', \'/opdracht_3\')">Stap 3</div>\n' +
    '                            <div class="sub-tab-grey" ng-if="opdr3StateR != \'3\'" ng-click="changeState(\'opdr3\',\'3\', \'/opdracht_3\')">Stap 4</div>\n' +
    '                            <div class="sub-tab-white" ng-if="opdr3StateR == \'3\'" ng-click="changeState(\'opdr3\',\'3\', \'/opdracht_3\')">Stap 4</div>\n' +
    '                            <div class="sub-tab-grey" ng-if="opdr3StateR != \'4\'" ng-click="changeState(\'opdr3\',\'4\', \'/opdracht_3\')">Stap 5</div>\n' +
    '                            <div class="sub-tab-white" ng-if="opdr3StateR == \'4\'" ng-click="changeState(\'opdr3\',\'4\', \'/opdracht_3\')">Stap 5</div>\n' +
    '                        </div>\n' +
    '                    </div>-->\n' +
    '                    </div>\n' +
    '                    <div class="nav-click"><a ng-click="changeState(\'opdr4\',\'0\', \'/opdracht_4\')">EXPERIMENTEREN</a>\n' +
    '                        <!--<div class="sub-tabs">\n' +
    '                        <div class="sub-tab-grey" ng-if="opdr4StateR != \'0\'" ng-click="changeState(\'opdr4\',\'0\', \'/opdracht_4\')">Stap 1</div>\n' +
    '                        <div class="sub-tab-white" ng-if="opdr4StateR == \'0\'" ng-click="changeState(\'opdr4\',\'0\', \'/opdracht_4\')">Stap 1</div>\n' +
    '                        <div class="sub-tab-grey" ng-if="opdr4StateR != \'1\'" ng-click="changeState(\'opdr4\',\'1\', \'/opdracht_4\')">Stap 2</div>\n' +
    '                        <div class="sub-tab-white" ng-if="opdr4StateR == \'1\'" ng-click="changeState(\'opdr4\',\'1\', \'/opdracht_4\')">Stap 2</div>\n' +
    '                        <div class="sub-tab-grey" ng-if="opdr4StateR != \'2\'" ng-click="changeState(\'opdr4\',\'2\', \'/opdracht_4\')">Stap 3</div>\n' +
    '                        <div class="sub-tab-white" ng-if="opdr4StateR == \'2\'" ng-click="changeState(\'opdr4\',\'2\', \'/opdracht_4\')">Stap 3</div>\n' +
    '                    </div>-->\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="nav-hide" ng-click="menuFunction(\'close\')" ng-if="_showMenu"><i class="material-icons">close</i></div>\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '        <script src="js/bundle.js"></script>\n' +
    '        <!--<div class="STRAIGHT-LINE"></div>-->\n' +
    '</body>\n' +
    '\n' +
    '</html>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/eindscherm.html',
    '<!--<div class="square-introductie" ng-click="sendCommand()">introductie</div>-->\n' +
    '<div class="eindscherm-bg"></div>\n' +
    '\n' +
    '<div class="eindscherm">\n' +
    '\n' +
    '    <!--<div class="achtergrond"></div>-->\n' +
    '    <div class="first-screen">\n' +
    '        <div class="titel-first">EINSTEIN WAS HERE</div>\n' +
    '        <div class="beschrijving-1">Nu ben je klaar om zelf onderzoeker te worden.\n' +
    '        </div>\n' +
    '        <div class="beschrijving-1 button" onclick="window.open(\'https://www.teylersmuseum.nl/nl/bezoek-het-museum/scholen-kinderen-en-groepen/scholen/voortgezet-onderwijs/programmas-en-projecten/einstein-was-here\')">Kom met je klas naar het Lorentz lab.</div>\n' +
    '        <div class="beschrijving-1"><br>Wil je meer weten over elektriciteit?<br>Kijk verder met onderstaand links.\n' +
    '        </div>\n' +
    '        <div class="lijn-wit"></div>\n' +
    '        <!--<div class="knoppen">\n' +
    '            <div class="knop-docent">Workshop</div>\n' +
    '            <div class="knop-docent">Moderne ontwikkelingen</div>\n' +
    '            <div class="knop-docent" ng-click="openLinks = !openLinks">Meer links</div>\n' +
    '        </div>-->\n' +
    '        <!--<div class="extra-links" ng-if="openLinks">-->\n' +
    '        <div class="knoppen" ng-if="buttonPC">\n' +
    '            <div class="knop-docent" onclick="window.open(\'http://proevenvanvroeger.nl/eindopdrachten/elektriciteit/index.html\')">\n' +
    '                <div class="double-line">PROEVEN VAN VROEGER</div>\n' +
    '            </div>\n' +
    '            <!--<div class="knop-docent" onclick="window.open(\'http://ifthenisnow.eu/nl/artikelen/van-barnsteen-tot-elektriciteit\')">\n' +
    '                <div class="double-line">VAN BARNSTEEN TOT ELEKTRICITEIT</div>\n' +
    '            </div>-->\n' +
    '\n' +
    '            <div class="knop-docent" onclick="window.open(\'https://nl.wikipedia.org/wiki/William_Gilbert\')">\n' +
    '                <div class="inside-text">WILLIAM GILBERT</div>\n' +
    '            </div>\n' +
    '            <div class="knop-docent" onclick="window.open(\'https://nl.wikipedia.org/wiki/William_Watson\')">\n' +
    '                <div class="inside-text">WILLIAM WATSON</div>\n' +
    '            </div>\n' +
    '            <div class="knop-docent" onclick="window.open(\'https://youtu.be/g5mzFb2hhHY\')">\n' +
    '                <div class="inside-text">BLIKSEM</div>\n' +
    '            </div>\n' +
    '            <!-- <div class="knop-docent" onclick="window.open(\'https://en.wikipedia.org/wiki/Lightning\')">\n' +
    '                <div class="inside-text">LIGHTNING</div>\n' +
    '            </div> -->\n' +
    '            <div class="knop-docent" onclick="window.open(\'https://www.youtube.com/watch?v=MBRTR2dlwvA\')">\n' +
    '                <div class="double-line">DE ONTDEKKING VAN DE ELEKTRICITEIT</div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="knop-docent" onclick="window.open(\'https://nl.wikipedia.org/wiki/Poollicht\')">\n' +
    '                <div class="inside-text">POOLLICHT</div>\n' +
    '            </div>\n' +
    '            <div class="knop-docent" onclick="window.open(\'http://www.schooltv.nl/video/de-kennis-van-nu-in-de-klas-einstein/#q=\')">\n' +
    '                <div class="double-line">DIEDERIK JEKEL OVER EINSTEIN</div>\n' +
    '            </div>\n' +
    '            <div class="knop-docent" onclick="window.open(\'http://www.schooltv.nl/video/clipphanger-wie-was-einstein/\')">\n' +
    '                <div class="inside-text">EINSTEIN</div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="knoppen tablet" ng-if="buttonTablet">\n' +
    '            <div class="knop-docent" onclick="window.open(\'http://proevenvanvroeger.nl/eindopdrachten/elektriciteit/index.html\')">\n' +
    '                <div class="double-line">PROEVEN VAN VROEGER</div>\n' +
    '            </div>\n' +
    '            <!--<div class="knop-docent" onclick="window.open(\'http://ifthenisnow.eu/nl/artikelen/van-barnsteen-tot-elektriciteit\')">\n' +
    '                <div class="double-line">VAN BARNSTEEN TOT ELEKTRICITEIT</div>\n' +
    '            </div>-->\n' +
    '\n' +
    '            <div class="knop-docent" onclick="window.open(\'https://nl.wikipedia.org/wiki/William_Gilbert\')">\n' +
    '                <div class="inside-text">WILLIAM GILBERT</div>\n' +
    '            </div>\n' +
    '            <div class="knop-docent" onclick="window.open(\'https://nl.wikipedia.org/wiki/William_Watson\')">\n' +
    '                <div class="inside-text">WILLIAM WATSON</div>\n' +
    '            </div>\n' +
    '            <div class="knop-docent" onclick="window.open(\'https://youtu.be/g5mzFb2hhHY\')">\n' +
    '                <div class="inside-text">BLIKSEM</div>\n' +
    '            </div>\n' +
    '            <div class="knop-docent" onclick="window.open(\'https://en.wikipedia.org/wiki/Lightning\')">\n' +
    '                <div class="inside-text">LIGHTNING</div>\n' +
    '            </div>\n' +
    '            <div class="knop-docent" onclick="window.open(\'https://www.youtube.com/watch?v=MBRTR2dlwvA\')">\n' +
    '                <div class="double-line">DE ONTDEKKING VAN DE ELEKTRICITEIT</div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="knop-docent" onclick="window.open(\'https://nl.wikipedia.org/wiki/Poollicht\')">\n' +
    '                <div class="inside-text">POOLLICHT</div>\n' +
    '            </div>\n' +
    '            <div class="knop-docent" onclick="window.open(\'http://www.schooltv.nl/video/de-kennis-van-nu-in-de-klas-einstein/#q=\')">\n' +
    '                <div class="double-line">DIEDERIK JEKEL OVER EINSTEIN</div>\n' +
    '            </div>\n' +
    '            <div class="knop-docent" onclick="window.open(\'http://www.schooltv.nl/video/clipphanger-wie-was-einstein/\')">\n' +
    '                <div class="inside-text">EINSTEIN</div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/introductie.html',
    '<!--<div class="square-introductie" ng-click="sendCommand()">introductie</div>-->\n' +
    '<div class="first-screen-background" ng-if="_displayState == \'one\'"></div>\n' +
    '<div class="background-intro-3" ng-if="_displayState == \'three\'"> </div>\n' +
    '<div class="introductie">\n' +
    '\n' +
    '    <div class="first-screen" ng-if="_displayState == \'one\'">\n' +
    '\n' +
    '        <div class="titel-first">EINSTEIN WAS HERE</div>\n' +
    '        <div class="beschrijving-1">EXPERIMENTEREN IN HET LORENTZ LAB\n' +
    '        </div>\n' +
    '        <div class="lijn-wit"></div>\n' +
    '        <div class="extra-knop" ng-click="" ng-if="buttonPC" onclick="window.open(\'../assets/pdf/T-Lorenz Lab educatie doc1.pdf\')" target="_blank">\n' +
    '            <div class="knop-content">DOCENT</div>\n' +
    '\n' +
    '\n' +
    '        </div>\n' +
    '        <div class="knoppen">\n' +
    '            <div class="knop-les" ng-click="changeState(\'two\')">VOORBEREIDENDE LES</div>\n' +
    '            <div class="knop-docent" onclick="window.open(\'../assets/pdf/Achtergrondinformatie.pdf\')" target="_blank">ACHTERGRONDINFORMATIE</div>\n' +
    '        </div>\n' +
    '        <!-- <div class="extra-knop-tablet" ng-click="" ng-if="buttonTablet" onclick="window.open(\'../assets/pdf/T-Lorenz Lab educatie doc1.pdf\')" target="_blank">\n' +
    '            <div class="knop-content">DOCENT</div>\n' +
    '            \n' +
    '        </div> -->\n' +
    '\n' +
    '    </div>\n' +
    '    <div class=s-bg ng-if="_displayState == \'two\'"></div>\n' +
    '\n' +
    '    <div class="second-screen" ng-if="_displayState == \'two\'">\n' +
    '\n' +
    '        <div class="titel">INTRODUCTIE TEYLERS MUSEUM</div>\n' +
    '        <div class="background">\n' +
    '\n' +
    '\n' +
    '            <div class="v-button1" ng-click="openVideo(\'1\')"> </div>\n' +
    '\n' +
    '            <div class="videoScreen" ng-if="openVideoScreen1" id="videoScreen">\n' +
    '                <div class="video" ng-click="closeVideo()">\n' +
    '                    <iframe width="1280" height="1080" frameBorder="0" src="./assets/movies/introductieteylers.mp4" autoplay=\'true\'></iframe>\n' +
    '                </div>\n' +
    '                <div class="closeButton" ng-click="closeVideo()">X</div>\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="third-screen" ng-if="_displayState == \'three\'">\n' +
    '\n' +
    '        <div class="text-area">\n' +
    '            <div class="first-text">Nobelprijswinnaar Hendrik Lorentz had een natuurkundig laboratorium in Teylers Museum. Niemand minder dan Albert Einstein kwam hem daar regelmatig bezoeken. Treed in hun voetsporen en verdiep je in de proeven van beroemde wetenschappers als\n' +
    '                Volta, Ampère, Ørsted en Faraday. Ontdek hoe onze kennis over elektriciteit en magnetisme is ontstaan.\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<!-- First -->\n' +
    '<div class="placeholder-button" ng-if="_displayState == \'one\'">\n' +
    '\n' +
    '</div>\n' +
    '<!-- Second -->\n' +
    '<div class="placeholder-button" ng-if="_displayState == \'two\'">\n' +
    '    <div class="buttons" ng-if="buttonPC">\n' +
    '        <button ng-click="changeState(\'one\')">TERUG</button>\n' +
    '        <button ng-click="changeState(\'three\')">VERDER</button>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<!-- Third -->\n' +
    '<div class="placeholder-button" ng-if="_displayState == \'three\'">\n' +
    '    <div class="buttons" ng-if="buttonPC">\n' +
    '        <button ng-click="changeState(\'two\')">TERUG</button>\n' +
    '        <button ng-click="go(\'/opdracht_1\')">VERDER</button>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/opdracht_1.html',
    '<!--Eerste scherm-->\n' +
    '\n' +
    '<div ng-if="_optionData != {}">\n' +
    '    <div class="background-opdr1" ng-if="opdr1StateR == \'0\'"></div>\n' +
    '    <div class="opdracht-1">\n' +
    '\n' +
    '        <div class="first-screen" ng-if="opdr1StateR == \'0\'">\n' +
    '            <div class="titel">ELEKTRISCHE VERSCHIJNSELEN </div>\n' +
    '\n' +
    '            <div class="blobs" ng-repeat="blob in _blobs track by $index">\n' +
    '                <div class="blob-grey" ng-if="$index != opdr1StateR"></div>\n' +
    '                <div class="blob-white" ng-if="$index == opdr1StateR"></div>\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '            <div class="text-area">\n' +
    '                <div class="first-text">In 1784 liet de directeur van Teylers Museum, Martinus van Marum, de grootste elektriseermachine ter wereld bouwen. Elektriciteit was ‘in’. Overal werden experimenten gedaan om dit verschijnsel beter te begrijpen. Er waren al wel elektrische\n' +
    '                    verschijnselen bekend, maar ze werden niet goed begrepen:\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="second-text">\n' +
    '                    ELEKTRISCHE VISSEN / MAGNETISCHE STENEN / STATISCHE ELEKTRICITEIT / <br> BLIKSEM / POOLLICHT / SINT ELMUSVUUR </div>\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '            <!-- <div class="buttons-tablet" ng-if="buttonTablet">\n' +
    '                <div class="button-2" ng-click="goPrev(\'/introductie\')">\n' +
    '                    <</div>\n' +
    '                        <div class="button-2" ng-click="changeState(\'1\')">></div>\n' +
    '                </div> -->\n' +
    '\n' +
    '        </div>\n' +
    '        <!--Tweede scherm-->\n' +
    '        <div class="second-screen" ng-if="opdr1StateR == \'1\'">\n' +
    '            <div class="titel">ELEKTRISCHE VERSCHIJNSELEN </div>\n' +
    '\n' +
    '            <div class="blobs" ng-repeat="blob in _blobs track by $index">\n' +
    '                <div class="blob-grey" ng-if="$index != opdr1StateR"></div>\n' +
    '                <div class="blob-white" ng-if="$index == opdr1StateR"></div>\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '            <div class="description">Verdeel de klas in groepjes.</br>\n' +
    '                Ieder groepje kiest één van onderstaande fenomenen om te bespreken. </div>\n' +
    '            <div class="content">\n' +
    '                <div class="rij-1">\n' +
    '                    <div class="cirkel rij-1" ng-repeat="cirkel in _optionData.row1 track by $index" ng-click="selectCirkel(1, $index)">\n' +
    '                        <img class="cirkel-img" ng-if="$index == _selectedCirkel && _selectedRow == \'1\'" src={{cirkel.img}}>\n' +
    '                        <img class="cirkel-grayscale" ng-if="$index != _selectedCirkel || _selectedRow != \'1\'" src={{cirkel.img}}>\n' +
    '                        <div class="line" ng-if="$index ==_selectedCirkel && _selectedRow == \'1\'"></div>\n' +
    '                        <div class="line-black" ng-if="$index !=_selectedCirkel || _selectedRow != \'1\'"></div>\n' +
    '                        <div class="text" ng-if="$index == _selectedCirkel && _selectedRow == \'1\'">{{cirkel.title}}</div>\n' +
    '                        <div class="text-black" ng-if="$index != _selectedCirkel || _selectedRow != \'1\'">{{cirkel.title}}</div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                </br>\n' +
    '                <div class="rij-2">\n' +
    '                    <div class="cirkel" ng-repeat="cirkel in _optionData.row2 track by $index" ng-click="selectCirkel(2, $index)">\n' +
    '                        <img class="cirkel-img" ng-if="$index == _selectedCirkel && _selectedRow == \'2\'" src={{cirkel.img}}>\n' +
    '                        <img class="cirkel-grayscale" ng-if="$index != _selectedCirkel || _selectedRow != \'2\'" src={{cirkel.img}}>\n' +
    '                        <div class="line" ng-if="$index ==_selectedCirkel && _selectedRow == \'2\'"></div>\n' +
    '                        <div class="line-black" ng-if="$index !=_selectedCirkel || _selectedRow != \'2\'"></div>\n' +
    '                        <div class="text" ng-if="$index == _selectedCirkel && _selectedRow == \'2\'">{{cirkel.title}}</div>\n' +
    '                        <div class="text-black" ng-if="$index != _selectedCirkel || _selectedRow != \'2\'">{{cirkel.title}}</div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                </br>\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '        <!--Derde scherm-->\n' +
    '        <div class="third-screen" ng-if="opdr1StateR == \'2\'">\n' +
    '            <div class="titel">ELEKTRISCHE VERSCHIJNSELEN</div>\n' +
    '            <div class="blobs" ng-repeat="blob in _blobs track by $index">\n' +
    '                <div class="blob-grey" ng-if="$index != opdr1StateR"></div>\n' +
    '                <div class="blob-white" ng-if="$index == opdr1StateR"></div>\n' +
    '            </div>\n' +
    '\n' +
    '            <!--Electrische vissen-->\n' +
    '            <div ng-if="_selectedRow == 1 && _selectedCirkel == 0">\n' +
    '                <div class="background">\n' +
    '                    <div class="video">\n' +
    '                        <iframe width="1080" frameBorder="0" src="{{vidSrc_1_1}}" autoplay=\'true\'></iframe>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="text-area">\n' +
    '                    <div class="first-text">Bekijk de informatie en bespreek kort de onderstaande vragen met elkaar. <br>Klik daarna op verder om erachter te komen hoe het echt zit.\n' +
    '                    </div>\n' +
    '\n' +
    '                    <div class="second-text">\n' +
    '                        ................... Hoe verklaarde men rond 1750 dit verschijnsel? ...................</br>\n' +
    '                        ................ Hoe wordt dit fenomeen tegenwoordig verklaard? ................</br>\n' +
    '                        <!--.................................... HOE BESCHERMDE MEN ZICH HIER TEGEN? ....................................</br>-->\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '            <!--Poollicht-->\n' +
    '            <div ng-if="_selectedRow == 1 && _selectedCirkel == 1">\n' +
    '                <div class="background">\n' +
    '                    <div class="video foto">\n' +
    '                        <img src="./assets/images/opdracht1/assets/poollicht.jpg">\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="text-area">\n' +
    '                    <div class="first-text">Bekijk de foto en bespreek kort de onderstaande vragen met elkaar. Klik daarna op verder om erachter te komen hoe het echt zit.\n' +
    '                    </div>\n' +
    '\n' +
    '                    <div class="second-text">\n' +
    '                        ................... Hoe verklaarde men rond 1750 dit verschijnsel? ...................</br>\n' +
    '                        ................. Hoe wordt dit fenomeen tegenwoordig verklaard? .................</br>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '            <!--Bliksem-->\n' +
    '            <div ng-if="_selectedRow == 1 && _selectedCirkel == 2">\n' +
    '                <div class="background">\n' +
    '                    <div class="video foto5">\n' +
    '                        <img src="./assets/images/opdracht1/assets/bliksem.jpg">\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="text-area">\n' +
    '                    <div class="first-text">Bekijk de foto en bespreek kort de onderstaande vragen met elkaar. Klik daarna op verder om erachter te komen hoe het echt zit.\n' +
    '                    </div>\n' +
    '\n' +
    '                    <div class="second-text">\n' +
    '                        ................... Hoe verklaarde men rond 1750 dit verschijnsel? ...................</br>\n' +
    '                        ................. Hoe wordt dit fenomeen tegenwoordig verklaard? .................</br>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '            <!--Magnetische stenen-->\n' +
    '            <div ng-if="_selectedRow == 2 && _selectedCirkel == 0">\n' +
    '                <div class="background">\n' +
    '\n' +
    '\n' +
    '                    <div class="video foto2">\n' +
    '                        <img src="./assets/images/opdracht1/assets/magnetische-stenen.jpg">\n' +
    '                    </div>\n' +
    '\n' +
    '                </div>\n' +
    '                <div class="text-area">\n' +
    '                    <div class="first-text">Bekijk de foto en bespreek kort de onderstaande vragen met elkaar. Klik daarna op verder om erachter te komen hoe het echt zit.\n' +
    '                    </div>\n' +
    '\n' +
    '                    <div class="second-text">\n' +
    '                        ................... Hoe verklaarde men rond 1750 dit verschijnsel? ...................</br>\n' +
    '                        ................. Hoe wordt dit fenomeen tegenwoordig verklaard? .................</br>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '            <!--Sint elmusvuur-->\n' +
    '            <div ng-if="_selectedRow == 2 && _selectedCirkel == 1">\n' +
    '                <div class="background">\n' +
    '                    <div class="video foto3">\n' +
    '                        <img src="./assets/images/opdracht1/assets/elmusvuur.jpg">\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '\n' +
    '                <div class="text-area">\n' +
    '                    <div class="first-text">Bekijk de foto en bespreek kort de onderstaande vragen met elkaar. Klik daarna op verder om erachter te komen hoe het echt zit.\n' +
    '                    </div>\n' +
    '\n' +
    '                    <div class="second-text">\n' +
    '                        ................... Hoe verklaarde men rond 1750 dit verschijnsel? ...................</br>\n' +
    '                        ................. Hoe wordt dit fenomeen tegenwoordig verklaard? .................</br>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '            <!--Statische electriciteit-->\n' +
    '            <div ng-if="_selectedRow == 2 && _selectedCirkel == 2">\n' +
    '                <div class="background">\n' +
    '                    <div class="video foto4">\n' +
    '                        <img src="./assets/images/opdracht1/assets/statisch.bmp">\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="text-area">\n' +
    '                    <div class="first-text">Bekijk de foto en bespreek kort de onderstaande vragen met elkaar. Klik daarna op verder om erachter te komen hoe het echt zit.\n' +
    '                    </div>\n' +
    '\n' +
    '                    <div class="second-text">\n' +
    '                        ................... Hoe verklaarde men rond 1750 dit verschijnsel? ...................</br>\n' +
    '                        ................. Hoe wordt dit fenomeen tegenwoordig verklaard? .................</br>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '        </div>\n' +
    '        <!-- </div> -->\n' +
    '\n' +
    '        <!--Vierde scherm-->\n' +
    '        <div class="fourth-screen" ng-if="opdr1StateR == \'3\'">\n' +
    '            <div class="titel">ELEKTRISCHE VERSCHIJNSELEN</div>\n' +
    '            <div class="blobs" ng-repeat="blob in _blobs track by $index">\n' +
    '                <div class="blob-grey" ng-if="$index != opdr1StateR"></div>\n' +
    '                <div class="blob-white" ng-if="$index == opdr1StateR"></div>\n' +
    '            </div>\n' +
    '\n' +
    '            <!--Electrische vissen-->\n' +
    '            <div ng-if="_selectedRow == 1 && _selectedCirkel == 0">\n' +
    '                <div class="background">\n' +
    '                    <div class="video">\n' +
    '                        <iframe width="1080" frameBorder="0" src="{{vidSrc_2_1}}" autoplay=\'true\'></iframe>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="text-area">\n' +
    '                    <div class="first-text">Hierboven kun je zien hoe men het verschijnsel tegenwoordig verklaart. Komt het overeen met wat jullie bedacht hadden?\n' +
    '                    </div>\n' +
    '                    <div class="third-text"> Hoe verklaarde men het vroeger?</div>\n' +
    '                    <div class="second-text">\n' +
    '                        Galvani dacht dat dieren hun eigen ‘dierlijke elektriciteit’ konden opslaan in een soort Leidse flessen in de spieren van hun lichaam. <br>Volta dacht dat de dieren met een deel van hun lichaam stroom konden opwekken; ze hadden\n' +
    '                        een soort batterij in hun lichaam.\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '            <!--Poollicht-->\n' +
    '            <div ng-if="_selectedRow == 1 && _selectedCirkel == 1">\n' +
    '                <div class="background">\n' +
    '                    <div class="video">\n' +
    '                        <iframe width="1080" frameBorder="0" src="./assets/movies/poollicht.mp4" autoplay=\'true\'></iframe>\n' +
    '                        <!-- <iframe width="1080" frameBorder="0" src="{{vidSrc_2_2}}" autoplay=\'true\'></iframe> -->\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="text-area">\n' +
    '                    <div class="first-text">Hierboven kun je zien hoe men het verschijnsel tegenwoordig verklaart. Komt het overeen met wat jullie bedacht hadden?\n' +
    '                    </div>\n' +
    '                    <div class="third-text"> Hoe verklaarde men het vroeger?</div>\n' +
    '                    <div class="second-text">\n' +
    '\n' +
    '                        Mensen zagen van alles in het grillige poollicht dat als een soort gordijn in de lucht hing. <br>Sommigen meenden dat het geesten waren van mensen die onlangs gestorven waren.\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '            <!--Bliksem-->\n' +
    '            <div ng-if="_selectedRow == 1 && _selectedCirkel == 2">\n' +
    '                <div class="background">\n' +
    '                    <div class="video">\n' +
    '                        <iframe width="1080" frameBorder="0" src="{{vidSrc_2_3}}" autoplay=\'true\'></iframe>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="text-area">\n' +
    '                    <div class="first-text">Hierboven kun je zien hoe men het verschijnsel tegenwoordig verklaart. Komt het overeen met wat jullie bedacht hadden?\n' +
    '                    </div>\n' +
    '                    <div class="third-text">Hoe verklaarde men het vroeger?</div>\n' +
    '                    <div class="second-text">\n' +
    '                        Bliksemschichten lijken op de vonken die gemaakt worden met elektriseermachines. Benjamin Franklin bedacht in de 18e eeuw dat er niet twee soorten elektrische stof waren maar één. Meestal hadden voorwerpen precies genoeg van die stof en was het voorwerp\n' +
    '                        neutraal: \'ongeladen\'. Maar soms was er tekort of juist teveel van de stof. De aanvulling van dit tekort gaat gepaard met een vonk: hij noemde dit \'elektrisch vuur\'.\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '            <!--Magnetische stenen-->\n' +
    '            <div ng-if="_selectedRow == 2 && _selectedCirkel == 0">\n' +
    '                <div class="background">\n' +
    '                    <div class="video foto6">\n' +
    '                        <img src="./assets/images/opdracht1/assets/magnetische-stenen.jpg">\n' +
    '                    </div>\n' +
    '\n' +
    '                </div>\n' +
    '                <div class="text-area">\n' +
    '                    <div class="first-text">Klik op <i>Informatie</i> om te weten hoe het verschijnsel magnetische stenen tegenwoordig verklaard wordt.\n' +
    '                    </div>\n' +
    '\n' +
    '                    <!--<button onclick="window.open(\'https://nl.wikipedia.org/wiki/Magnetiet\')">Meer informatie</button>-->\n' +
    '                    <div class="third-text">Hoe verklaarde men het vroeger?</div>\n' +
    '                    <div class="second-text">\n' +
    '                        Descartes dacht dat er rond een magneet wervelingen plaats vinden van spiraalvormige deeltjes. Die kwamen er bij de ene pool uit en gingen er bij de andere weer in. Ook rond de aarde waren die wervelingen aanwezig. De naald van een kompas had poriën waarin\n' +
    '                        de deeltjes precies pasten: daarom gaat de kompasnaald in de richting van de werveling staan. </div>\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '            <!--Sint elmusvuur-->\n' +
    '            <div ng-if="_selectedRow == 2 && _selectedCirkel == 1">\n' +
    '                <div class="background">\n' +
    '                    <div class="video">\n' +
    '                        <iframe width="1080" frameBorder="0" src="{{vidSrc_2_5}}" autoplay=\'true\'></iframe>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="text-area">\n' +
    '                    <div class="first-text">Bekijk het filmpje of klik op <i>Informatie</i> om te weten hoe het verschijnsel Sint Elmusvuur tegenwoordig verklaard wordt. Scroll in het artikel even naar beneden.</div>\n' +
    '                    <div class="third-text">Hoe verklaarde met het fenomeen rond 1750?</div>\n' +
    '                    <div class="second-text">\n' +
    '                        Sint-Elsmusvuur is een blauwwit verschijnsel en het lijkt op een dansende vlam. Shakespeare wijdt in zijn stuk The Tempest het ontstaan ervan aan de geest Ariel, die in dienst staat van de magiër Prospero. Het ‘vuur’ wordt soms gezien door zeelui in de\n' +
    '                        top van een mast, want het verschijnsel treedt het sterkst op bij scherpe punten. Het doet zich alleen voor in specifieke weersomstandigheden, als de lucht geladen is met elektriciteit, zoals in de aanloop naar een storm.\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '            </div>\n' +
    '            <!--Statische electrisciteit-->\n' +
    '            <div ng-if="_selectedRow == 2 && _selectedCirkel == 2">\n' +
    '                <div class="background">\n' +
    '                    <div class="video">\n' +
    '                        <iframe width="1080" frameBorder="0" src="{{vidSrc_2_6}}" autoplay=\'true\'></iframe>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="text-area">\n' +
    '                    <div class="first-text">Hierboven kun je zien hoe men het verschijnsel tegenwoordig verklaart. Komt het overeen met wat jullie bedacht hadden?\n' +
    '                    </div>\n' +
    '                    <div class="third-text">Hoe verklaarde men het vroeger?</div>\n' +
    '                    <div class="second-text">\n' +
    '                        William Gilbert dacht in de 16e eeuw dat er door wrijving een \'effluvium\' vrij kwam in het materiaal. Charles Dufay dacht dat er twee soorten elektriciteit waren. William Watson veronderstelde in de 18e eeuw dat er een ‘elektrische ether’ is die overgedragen\n' +
    '                        wordt van het ene op het andere voorwerp. Daardoor kan het ene voorwerp een tekort hebben aan deze \'ether\' en het andere een overschot. Ook Franklin, Van Marum en Volta hingen deze één-vloeistof-theorie aan.\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <!-- Eerste -->\n' +
    '    <div class="placeholder-button" ng-if="opdr1StateR == \'0\'">\n' +
    '        <div class="buttons" ng-if="buttonPC">\n' +
    '            <button ng-click="goPrev(\'/introductie\')">TERUG</button>\n' +
    '            <button ng-click="changeState(\'1\')">VERDER</button>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <!-- Tweede -->\n' +
    '    <div class="placeholder-button" ng-if="opdr1StateR == \'1\'">\n' +
    '        <div class="buttons" ng-if="buttonPC">\n' +
    '            <button ng-click="changeState(\'0\')">TERUG</button>\n' +
    '            <button ng-click="changeState(\'2\')" ng-if="_selectedRow != 0">VERDER</button>\n' +
    '            <button ng-if="_selectedRow == 0" class="greyed-out">VERDER</button>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <!-- Derde -->\n' +
    '    <div class="placeholder-button" ng-if="opdr1StateR == \'2\'">\n' +
    '        <div class="buttons" ng-if="buttonPC">\n' +
    '            <button ng-click="changeState(\'1\')">TERUG</button>\n' +
    '            <button ng-click="changeState(\'3\')">VERDER</button>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <!-- Vierde -->\n' +
    '    <div class="placeholder-button" ng-if="opdr1StateR == \'3\'">\n' +
    '        <!-- Electrische vissen -->\n' +
    '        <div class="buttons-2" ng-if="_selectedRow == 1 && _selectedCirkel == 0">\n' +
    '            <button ng-click="changeState(\'2\')">TERUG</button>\n' +
    '            <button onclick="window.open(\'http://wibnet.nl/dieren/zeedieren/vissen/waarom-zijn-sommige-vissen-elektrisch\')">INFORMATIE</button>\n' +
    '            <button ng-click="goNext(\'/opdracht_2\')">VERDER</button>\n' +
    '        </div>\n' +
    '\n' +
    '        <!-- Magnetische stenen -->\n' +
    '        <div class="buttons-2" ng-if="_selectedRow == 2 && _selectedCirkel == 0">\n' +
    '            <button ng-click="changeState(\'1\')">TERUG</button>\n' +
    '            <button onclick="window.open(\'https://nl.wikipedia.org/wiki/Magnetiet\')">INFORMATIE</button>\n' +
    '            <button ng-click="goNext(\'/opdracht_2\')">VERDER</button>\n' +
    '        </div>\n' +
    '\n' +
    '        <!-- Sint elmusvuur -->\n' +
    '        <div class="buttons-2" ng-if="_selectedRow == 2 && _selectedCirkel == 1">\n' +
    '            <button ng-click="changeState(\'2\')">TERUG</button>\n' +
    '            <button onclick="window.open(\'http://www.vwmagazine.be/nl/2012/11/06/natuurfenomenen-met-magische-allures/\')">INFORMATIE</button>\n' +
    '            <button ng-click="goNext(\'/opdracht_2\')">VERDER</button>\n' +
    '        </div>\n' +
    '\n' +
    '        <!-- Poollicht -->\n' +
    '        <div class="buttons-2" ng-if="_selectedRow == 1 && _selectedCirkel == 1">\n' +
    '            <button ng-click="changeState(\'2\')">TERUG</button>\n' +
    '            <button onclick="window.open(\'http://www.schooltv.nl/video/wat-is-het-poollicht-een-bijzonder-verschijnsel/#q=poollicht\')">INFORMATIE</button>\n' +
    '            <button ng-click="goNext(\'/opdracht_2\')">VERDER</button>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="_selectedRow == 1 && _selectedCirkel == 2">\n' +
    '        <div class="placeholder-button" ng-if="opdr1StateR == \'3\'">\n' +
    '            <div class="buttons" ng-if="buttonPC">\n' +
    '                <button ng-click="changeState(\'2\')">TERUG</button>\n' +
    '                <button ng-click="goNext(\'/opdracht_2\')">VERDER</button>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="_selectedRow == 2 && _selectedCirkel == 2">\n' +
    '        <div class="placeholder-button" ng-if="opdr1StateR == \'3\'">\n' +
    '            <div class="buttons" ng-if="buttonPC">\n' +
    '                <button ng-click="changeState(\'2\')">TERUG</button>\n' +
    '                <button ng-click="goNext(\'/opdracht_2\')">VERDER</button>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/opdracht_2.html',
    '<div class="background-opdr2-state1" ng-if="opdr2StateR == \'0\'">\n' +
    '</div>\n' +
    '<div ng-if="scientistData != {}">\n' +
    '    <div class="opdracht-2">\n' +
    '\n' +
    '        <div class="first-screen" ng-if="opdr2StateR == \'0\'">\n' +
    '            <div class="titel">ONTDEKKINGEN</div>\n' +
    '\n' +
    '            <div class="blobs" ng-repeat="blob in _blobs track by $index">\n' +
    '                <div class="blob-grey" ng-if="$index != opdr2StateR"></div>\n' +
    '                <div class="blob-white" ng-if="$index == opdr2StateR"></div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="text-area">\n' +
    '                <div class="first-text">Vanaf ca. 1750 bogen wetenschappers als Volta, Galvani, Ampère, Faraday, en Van Marum in Teylers Museum, zich over elektrische en magnetische verschijnselen en deden belangrijke ontdekkingen. In 1892 zorgde professor Hendrik Lorentz uiteindelijk\n' +
    '                    voor een doorbraak. Hij bedacht een formule die de kracht beschrijft op een bewegende lading als gevolg van het elektrische én magnetische veld: de Lorentzkracht.\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '        <!--Tweede scherm-->\n' +
    '        <div class="second-screen" ng-if="opdr2StateR == \'1\'">\n' +
    '            <div class="titel">ONTDEKKINGEN</div>\n' +
    '            <div class="blobs" ng-repeat="blob in _blobs track by $index">\n' +
    '                <div class="blob-grey" ng-if="$index != opdr2StateR"></div>\n' +
    '                <div class="blob-white" ng-if="$index == opdr2StateR"></div>\n' +
    '            </div>\n' +
    '            <div class="description">\n' +
    '\n' +
    '\n' +
    '                <div ng-if="buttonPC">Welke ontdekkingen op het gebied van elektriciteit en magnetisme zijn door welke wetenschappers gedaan?<br>Koppel de namen van de wetenschappers aan hun ontdekking.</div>\n' +
    '                <div ng-if="buttonTablet">Welke ontdekkingen op het gebied van elektriciteit en magnetisme zijn door welke wetenschappers gedaan?<br>Koppel de namen van de wetenschappers aan hun ontdekking.<br>Houd de knoppen één tel ingedrukt en sleep ze daarna naar de juiste\n' +
    '                    locatie.\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="content">\n' +
    '                <div class="scientists">\n' +
    '                    <div class="scientist" ng-drop="true" ng-drop-success="onDropComplete(5,$data,$event)">\n' +
    '                        <div ng-repeat="obj in droppedObjects_5 track by $index" ng-drag="true" ng-drag-data="obj" ng-drag-success="onDragComplete($data,$event)" class="dragger">\n' +
    '                            <div class="corner-square"></div>\n' +
    '                            <div class="name" ng-if="droppedObjects_5.length == 0">{{scientistData.item5.title}}</div>\n' +
    '                            <div class="name-down" ng-if="droppedObjects_5.length != 0">{{scientistData.item5.title}}</div>\n' +
    '                            <div>{{obj.content}}</div>\n' +
    '                        </div>\n' +
    '                        <img class="cirkel-img" src={{scientistData.item5.img}}>\n' +
    '                        <div class="name">Galvani</div>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <div class="scientist" ng-drop="true" ng-drop-success="onDropComplete(6,$data,$event)">\n' +
    '                        <div ng-repeat="obj in droppedObjects_6 track by $index" ng-drag="true" ng-drag-data="obj" ng-drag-success="onDragComplete($data,$event)" class="dragger">\n' +
    '                            <div class="corner-square"></div>\n' +
    '                            <div class="name" ng-if="droppedObjects_6.length == 0">{{scientistData.item6.title}}</div>\n' +
    '                            <div class="name-down" ng-if="droppedObjects_6.length != 0">{{scientistData.item6.title}}</div>\n' +
    '                            <div>{{obj.content}}</div>\n' +
    '                        </div>\n' +
    '                        <img class="cirkel-img" src={{scientistData.item6.img}}>\n' +
    '                        <div class="name">Ørsted</div>\n' +
    '                    </div>\n' +
    '\n' +
    '\n' +
    '                    <div class="scientist" ng-drop="true" ng-drop-success="onDropComplete(4,$data,$event)">\n' +
    '                        <div ng-repeat="obj in droppedObjects_4 track by $index" ng-drag="true" ng-drag-data="obj" ng-drag-success="onDragComplete($data,$event)" class="dragger">\n' +
    '                            <div class="corner-square"></div>\n' +
    '                            <div class="name" ng-if="droppedObjects_4.length == 0">{{scientistData.item4.title}}</div>\n' +
    '                            <div class="name-down" ng-if="droppedObjects_4.length != 0">{{scientistData.item4.title}}</div>\n' +
    '                            <div>{{obj.content}}</div>\n' +
    '                        </div>\n' +
    '                        <img class="cirkel-img" src={{scientistData.item4.img}}>\n' +
    '                        <div class="name">Volta</div>\n' +
    '                    </div>\n' +
    '\n' +
    '                </div>\n' +
    '                <div class="scientists row2">\n' +
    '\n' +
    '                    <div class="scientist" ng-drop="true" ng-drop-success="onDropComplete(3,$data,$event)">\n' +
    '                        <div ng-repeat="obj in droppedObjects_3 track by $index" ng-drag="true" ng-drag-data="obj" ng-drag-success="onDragComplete($data,$event)" class="dragger">\n' +
    '                            <div class="corner-square"></div>\n' +
    '                            <div class="name" ng-if="droppedObjects_3.length == 0">{{scientistData.item3.title}}</div>\n' +
    '                            <div class="name-down" ng-if="droppedObjects_3.length != 0">{{scientistData.item3.title}}</div>\n' +
    '                            <div>{{obj.content}}</div>\n' +
    '                        </div>\n' +
    '                        <img class="cirkel-img" src={{scientistData.item3.img}}>\n' +
    '                        <div class="name">Ampère</div>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <div class="scientist" ng-drop="true" ng-drop-success="onDropComplete(1,$data,$event)">\n' +
    '                        <div ng-repeat="obj in droppedObjects_1 track by $index" ng-drag="true" ng-drag-data="obj" ng-drag-success="onDragComplete($data,$event)" class="dragger">\n' +
    '                            <div class="corner-square"></div>\n' +
    '                            <div class="name" ng-if="droppedObjects_1.length == 0">{{scientistData.item1.title}}</div>\n' +
    '                            <div class="name-down" ng-if="droppedObjects_1.length != 0">{{scientistData.item1.title}}</div>\n' +
    '                            <div>{{obj.content}}</div>\n' +
    '                        </div>\n' +
    '                        <img class="cirkel-img" src={{scientistData.item1.img}}>\n' +
    '                        <div class="name">Lorentz</div>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <div class="scientist" ng-drop="true" ng-drop-success="onDropComplete(2,$data,$event)">\n' +
    '                        <div ng-repeat="obj in droppedObjects_2 track by $index" ng-drag="true" ng-drag-data="obj" ng-drag-success="onDragComplete($data,$event)" class="dragger">\n' +
    '                            <div class="corner-square"></div>\n' +
    '                            <div class="name" ng-if="droppedObjects_2.length == 0">{{scientistData.item2.title}}</div>\n' +
    '                            <div class="name-down" ng-if="droppedObjects_2.length != 0">{{scientistData.item2.title}}</div>\n' +
    '                            <div>{{obj.content}}</div>\n' +
    '                        </div>\n' +
    '                        <img class="cirkel-img" src={{scientistData.item2.img}}>\n' +
    '                        <div class="name">Faraday</div>\n' +
    '                    </div>\n' +
    '\n' +
    '\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="draggers" ng-repeat="obj in answers track by $index">\n' +
    '                    <div class="dragger" ng-drag="true" ng-drag-data="obj" data-allow-transform="true">\n' +
    '                        <!--{{obj.title}}-->\n' +
    '                        <div class="corner-square"></div>\n' +
    '                        <div class="drag-text">{{obj.content}}</div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '                <div class="status-message" ng-if="Check_C">\n' +
    '                    <div class="title">PRIMA!</div>\n' +
    '                    <div class="text">Helemaal goed!</div>\n' +
    '                </div>\n' +
    '                <div class="status-message" ng-if="Check">\n' +
    '                    <div class="title">HELAAS</div>\n' +
    '                    <div class="text">Dat is nog niet goed..</div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '        <!--Derde scherm-->\n' +
    '        <div class="third-screen" ng-if="opdr2StateR == \'2\'">\n' +
    '            <div class="titel">ONTDEKKINGEN</div>\n' +
    '            <div class="blobs" ng-repeat="blob in _blobs track by $index">\n' +
    '                <div class="blob-grey" ng-if="$index != opdr2StateR"></div>\n' +
    '                <div class="blob-white" ng-if="$index == opdr2StateR"></div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div ng-if="buttonPC" class="description">Zet de ontdekkers in de juiste volgorde op de tijdlijn.</div>\n' +
    '            <div ng-if="buttonTablet" class="description">Voeg de geschiedkundige gebeurtenissen op de juiste plek toe aan de tijdlijn.<br>Houd de knoppen één tel ingedrukt en sleep ze daarna naar de juiste locatie.</div>\n' +
    '\n' +
    '            <div class="content">\n' +
    '                <!--Array van sleepbare objecten-->\n' +
    '                <div class="scientists">\n' +
    '                    <div class="seperate" ng-repeat="obj in scientistsTimeLine track by $index" ng-drag="true" ng-drag-data="obj" class="big-scientist" ng-drag-success="onDragTLComplete($data,$event)" data-allow-transform="true">\n' +
    '                        <img class="cirkel-img" src="{{obj.img}}">\n' +
    '                        <div class="title">{{obj.title}}\n' +
    '                            <div class="rect"></div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="timeline">\n' +
    '                    <img class="tijdlijn1" src="{{timeLineData.tijdlijn1.img}}">\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="tl_array nummer_1" ng-drop="true" ng-drop-success="onDropTLComplete(1, $data,$event)">\n' +
    '                    <div ng-repeat="obj in TL_1 track by $index" ng-drag="true" ng-drag-data="obj" class="small-scientist">\n' +
    '                        <img class="cirkel-img-small" src="{{obj.img}}">\n' +
    '                        <div class="title-small">{{obj.title}}\n' +
    '                            <div class="rect"></div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="tl_array nummer_2" ng-drop="true" ng-drop-success="onDropTLComplete(2, $data,$event)">\n' +
    '                    <div ng-repeat="obj in TL_2 track by $index" ng-drag="true" ng-drag-data="obj" class="small-scientist">\n' +
    '                        <img class="cirkel-img-small" src="{{obj.img}}">\n' +
    '                        <div class="title-small">{{obj.title}}\n' +
    '                            <div class="rect"></div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="tl_array nummer_3" ng-drop="true" ng-drop-success="onDropTLComplete(3, $data,$event)">\n' +
    '                    <div ng-repeat="obj in TL_3 track by $index" ng-drag="true" ng-drag-data="obj" class="small-scientist">\n' +
    '                        <img class="cirkel-img-small" src="{{obj.img}}">\n' +
    '                        <div class="title-small">{{obj.title}}\n' +
    '                            <div class="rect"></div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="tl_array nummer_4" ng-drop="true" ng-drop-success="onDropTLComplete(4, $data,$event)">\n' +
    '                    <div ng-repeat="obj in TL_4 track by $index" ng-drag="true" ng-drag-data="obj" class="small-scientist">\n' +
    '                        <img class="cirkel-img-small" src="{{obj.img}}">\n' +
    '                        <div class="title-small">{{obj.title}}\n' +
    '                            <div class="rect"></div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="tl_array nummer_5" ng-drop="true" ng-drop-success="onDropTLComplete(5, $data,$event)">\n' +
    '                    <div ng-repeat="obj in TL_5 track by $index" ng-drag="true" ng-drag-data="obj" class="small-scientist">\n' +
    '                        <img class="cirkel-img-small" src="{{obj.img}}">\n' +
    '                        <div class="title-small">{{obj.title}}\n' +
    '                            <div class="rect"></div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="tl_array nummer_6" ng-drop="true" ng-drop-success="onDropTLComplete(6, $data,$event)">\n' +
    '                    <div ng-repeat="obj in TL_6 track by $index" ng-drag="true" ng-drag-data="obj" class="small-scientist">\n' +
    '                        <img class="cirkel-img-small" src="{{obj.img}}">\n' +
    '                        <div class="title-small">{{obj.title}}\n' +
    '                            <div class="rect"></div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '\n' +
    '                <div class="status-message" ng-if="TL_1_Check_C">\n' +
    '                    <div class="title">PRIMA!</div>\n' +
    '                    <div class="text">Helemaal goed!</div>\n' +
    '                </div>\n' +
    '                <div class="status-message" ng-if="TL_1_Check">\n' +
    '                    <div class="title">HELAAS</div>\n' +
    '                    <div class="text">Dat is nog niet goed..</div>\n' +
    '                </div>\n' +
    '\n' +
    '\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '        <!--Vierde scherm-->\n' +
    '        <div class="fourth-screen" ng-if="opdr2StateR == \'3\'">\n' +
    '            <div class="titel">ONTDEKKINGEN</div>\n' +
    '            <div class="blobs" ng-repeat="blob in _blobs track by $index">\n' +
    '                <div class="blob-grey" ng-if="$index != opdr2StateR"></div>\n' +
    '                <div class="blob-white" ng-if="$index == opdr2StateR"></div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="description">\n' +
    '                <div ng-if="buttonPC">Voeg de geschiedkundige gebeurtenissen op de juiste plek toe aan de tijdlijn.</div>\n' +
    '                <div ng-if="buttonTablet">Voeg de geschiedkundige gebeurtenissen op de juiste plek toe aan de tijdlijn.<br>Houd de knoppen één tel ingedrukt en sleep ze daarna naar de juiste locatie.</div>\n' +
    '                <div class="content">\n' +
    '                    <div class="timeline" ng-if="buttonPC">\n' +
    '                        <img class="tijdlijn1" src="{{timeLineData.tijdlijn2.img}}" </div>\n' +
    '                    </div>\n' +
    '                    <div class="timeline" ng-if="buttonTablet">\n' +
    '                        <img class="tijdlijn1" src="{{timeLineData.tijdlijn2mobile.img}}" </div>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <!--Deze zijn dus al gevuld-->\n' +
    '\n' +
    '                    <div class="tl_array nummer_1">\n' +
    '                        <div ng-repeat="obj in TL_1 track by $index" class="small-scientist">\n' +
    '                            <img class="cirkel-img-small" src="{{obj.img}}">\n' +
    '                            <div class="title-small">{{obj.title}}\n' +
    '                                <div class="rect"></div>\n' +
    '                            </div>\n' +
    '\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div class="tl_array nummer_2">\n' +
    '                        <div ng-repeat="obj in TL_2 track by $index" class="small-scientist">\n' +
    '                            <img class="cirkel-img-small" src="{{obj.img}}">\n' +
    '                            <div class="title-small">{{obj.title}}\n' +
    '                                <div class="rect"></div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div class="tl_array nummer_3">\n' +
    '                        <div ng-repeat="obj in TL_3 track by $index" class="small-scientist">\n' +
    '                            <img class="cirkel-img-small" src="{{obj.img}}">\n' +
    '                            <div class="title-small">{{obj.title}}\n' +
    '                                <div class="rect"></div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div class="tl_array nummer_4">\n' +
    '                        <div ng-repeat="obj in TL_4 track by $index" class="small-scientist">\n' +
    '                            <img class="cirkel-img-small" src="{{obj.img}}">\n' +
    '                            <div class="title-small">{{obj.title}}\n' +
    '                                <div class="rect"></div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div class="tl_array nummer_5">\n' +
    '                        <div ng-repeat="obj in TL_5 track by $index" class="small-scientist">\n' +
    '                            <img class="cirkel-img-small" src="{{obj.img}}">\n' +
    '                            <div class="title-small">{{obj.title}}\n' +
    '                                <div class="rect"></div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <div class="tl_array nummer_6">\n' +
    '                        <div ng-repeat="obj in TL_6 track by $index" class="small-scientist">\n' +
    '                            <img class="cirkel-img-small" src="{{obj.img}}">\n' +
    '                            <div class="title-small">{{obj.title}}\n' +
    '\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '\n' +
    '\n' +
    '                    <div class="gebeurtenissen">\n' +
    '                        <div class="seperate" ng-repeat="gebeurtenis in gebeurtenisTimeLine track by $index" ng-drag="true" ng-drag-data="gebeurtenis" class="big-gebeurtenis" ng-drag-success="onDragTLComplete($data,$event)" data-allow-transform="true">\n' +
    '                            <img class="cirkel-img" src="{{gebeurtenis.img}}">\n' +
    '                            <div class="title">\n' +
    '                                {{gebeurtenis.title}}\n' +
    '                                <div class="rect"></div>\n' +
    '\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <!--Deze moeten nog gevuld worden-->\n' +
    '                    <div ng-if="buttonPC">\n' +
    '                        <div class="tl_array_2 nummer_1_2" ng-drop="true" ng-drop-success="onDropGebeurtenisComplete(1, $data,$event)">\n' +
    '                            <div ng-repeat="obj in TL_1_2 track by $index" ng-drag="true" ng-drag-data="obj" class="small-gebeurtenis">\n' +
    '                                <img class="cirkel-img-small" src="{{obj.img}}">\n' +
    '                                <div class="title-small">{{obj.title}}\n' +
    '                                    <div class="rect">\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '\n' +
    '                        <div class="tl_array_2 nummer_2_2" ng-drop="true" ng-drop-success="onDropGebeurtenisComplete(2, $data,$event)">\n' +
    '                            <div ng-repeat="obj in TL_2_2 track by $index" ng-drag="true" ng-drag-data="obj" class="small-gebeurtenis">\n' +
    '                                <img class="cirkel-img-small" src="{{obj.img}}">\n' +
    '                                <div class="title-small">{{obj.title}}\n' +
    '                                    <div class="rect">\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                        <div class="tl_array_2 nummer_3_2" ng-drop="true" ng-drop-success="onDropGebeurtenisComplete(3, $data,$event)">\n' +
    '                            <div ng-repeat="obj in TL_3_2 track by $index" ng-drag="true" ng-drag-data="obj" class="small-gebeurtenis">\n' +
    '                                <img class="cirkel-img-small" src="{{obj.img}}">\n' +
    '                                <div class="title-small">{{obj.title}}\n' +
    '                                    <div class="rect">\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                        <div class="tl_array_2 nummer_4_2" ng-drop="true" ng-drop-success="onDropGebeurtenisComplete(4, $data,$event)">\n' +
    '                            <div ng-repeat="obj in TL_4_2 track by $index" ng-drag="true" ng-drag-data="obj" class="small-gebeurtenis">\n' +
    '                                <img class="cirkel-img-small" src="{{obj.img}}">\n' +
    '                                <div class="title-small">{{obj.title}}\n' +
    '                                    <div class="rect">\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                        <div class="tl_array_2 nummer_5_2" ng-drop="true" ng-drop-success="onDropGebeurtenisComplete(5, $data,$event)">\n' +
    '                            <div ng-repeat="obj in TL_5_2 track by $index" ng-drag="true" ng-drag-data="obj" class="small-gebeurtenis">\n' +
    '                                <img class="cirkel-img-small" src="{{obj.img}}">\n' +
    '                                <div class="title-small">{{obj.title}}\n' +
    '                                    <div class="rect">\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                        <div class="tl_array_2 nummer_6_2" ng-drop="true" ng-drop-success="onDropGebeurtenisComplete(6, $data,$event)">\n' +
    '                            <div ng-repeat="obj in TL_6_2 track by $index" ng-drag="true" ng-drag-data="obj" class="small-gebeurtenis">\n' +
    '                                <img class="cirkel-img-small" src="{{obj.img}}">\n' +
    '                                <div class="title-small">{{obj.title}}\n' +
    '                                    <div class="rect">\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div class="status-message" ng-if="TL_2_Check_C">\n' +
    '                        <div class="title">PRIMA!</div>\n' +
    '                        <div class="text">Helemaal goed!</div>\n' +
    '                    </div>\n' +
    '                    <div class="status-message" ng-if="TL_2_Check">\n' +
    '                        <div class="title">HELAAS</div>\n' +
    '                        <div class="text">Dat is nog niet goed..</div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '</div>\n' +
    '\n' +
    '<div class="placeholder-button" ng-if="opdr2StateR == \'0\'">\n' +
    '    <div class="buttons" ng-if="buttonPC">\n' +
    '        <button ng-click="goPrev(\'/opdracht_1\')">TERUG</button>\n' +
    '        <button ng-click="changeState(\'1\')">VERDER</button>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<div class="placeholder-button" ng-if="opdr2StateR == \'1\'">\n' +
    '    <div class="buttons" ng-if="buttonPC">\n' +
    '        <button ng-click="changeState(\'0\')">TERUG</button>\n' +
    '        <button ng-click="changeState(\'2\')" ng-if="correntAnswerCounter == 6">VERDER</button>\n' +
    '        <button class="greyed-out" ng-click="" ng-if="correntAnswerCounter != 6">VERDER</button>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<div class="placeholder-button" ng-if="opdr2StateR == \'2\'">\n' +
    '    <div class="buttons" ng-if="buttonPC">\n' +
    '        <button ng-click="changeState(\'1\')">TERUG</button>\n' +
    '        <!-- <button ng-click="changeState(\'3\')">VERDER</button> -->\n' +
    '        <button ng-click="changeState(\'3\')" ng-if="correntAnswerCounter_TL_1 == 6">VERDER</button>\n' +
    '        <button class="greyed-out" ng-click="" ng-if="correntAnswerCounter_TL_1 != 6">VERDER</button>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="placeholder-button" ng-if="opdr2StateR == \'3\'">\n' +
    '    <div class="buttons" ng-if="buttonPC">\n' +
    '        <button ng-click="changeState(\'2\')">TERUG</button>\n' +
    '        <button ng-click="goNext(\'/opdracht_3\')" ng-if="correntAnswerCounter_TL_2 == 6">VERDER</button>\n' +
    '        <button class="greyed-out" ng-click="" ng-if="correntAnswerCounter_TL_2 != 6">VERDER</button>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/opdracht_3.html',
    '<!--Eerste scherm-->\n' +
    '<div class="background-3-first" ng-if="opdr3StateR == \'0\'"></div>\n' +
    '\n' +
    '<div class="background_1" ng-if="opdr3StateR == \'2\' && selectedTheory == \'3\'"></div>\n' +
    '<div class="background_2" ng-if="opdr3StateR == \'2\' && selectedTheory == \'2\'"></div>\n' +
    '<div class="background_3" ng-if="opdr3StateR == \'2\' && selectedTheory == \'1\'"></div>\n' +
    '\n' +
    '<div class="background1" ng-if="opdr3StateR == \'4\' && selectedTheory == \'3\'"></div>\n' +
    '<div class="background2" ng-if="opdr3StateR == \'4\' && selectedTheory == \'2\'"></div>\n' +
    '<div class="background3" ng-if="opdr3StateR == \'4\' && selectedTheory == \'1\'"></div>\n' +
    '\n' +
    '<div class="opdracht-3">\n' +
    '    <div class="first-screen" ng-if="opdr3StateR == \'0\'">\n' +
    '        <div class="titel">WETENSCHAPPERS</div>\n' +
    '\n' +
    '        <div class="blobs" ng-repeat="blob in _blobs track by $index">\n' +
    '            <div class="blob-grey" ng-if="$index != opdr3StateR"></div>\n' +
    '            <div class="blob-white" ng-if="$index == opdr3StateR"></div>\n' +
    '        </div>\n' +
    '        <div class="text-area">\n' +
    '            <div class="first-text">Het lijkt nu wel alsof de ontdekkingen simpelweg van A naar B liepen en de wetenschap steeds een stapje verder brachten. Maar zo makkelijk was het niet…\n' +
    '                </br>\n' +
    '                </br>\n' +
    '                Er bestonden verschillende hypotheses over elektromagnetisme, die regelmatig bijgesteld werden. Soms werden resultaten van experimenten verkeerd geïnterpreteerd of over het hoofd gezien. Vaak hadden wetenschappers verschillende meningen.\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <!--Tweede scherm-->\n' +
    '    <div class="second-screen" ng-if="opdr3StateR == \'1\'">\n' +
    '        <div class="titel">WETENSCHAPPERS</div>\n' +
    '        <!--<div class="blob-placeholder">-->\n' +
    '        <div class="blobs" ng-repeat="blob in _blobs track by $index">\n' +
    '            <div class="blob-grey" ng-if="$index != opdr3StateR"></div>\n' +
    '            <div class="blob-white" ng-if="$index == opdr3StateR"></div>\n' +
    '        </div>\n' +
    '        <!--</div>-->\n' +
    '\n' +
    '        <div class="first-text">Wetenschappers hebben onderling flink gediscussieerd over onderstaande theorieën. <br>Verdeel de klas in groepjes en kies per groepje een theorie hieronder.</div>\n' +
    '        <div class="content" ng-if="buttonPC">\n' +
    '            <div class="container deel1" ng-click="selectTheory(1)">\n' +
    '                <div class="container-content">\n' +
    '                    <!--Als hij niet geselecteerd is-->\n' +
    '                    <img ng-if="selectedTheory == 0 || selectedTheory != 1" class="black-cirkel-img" src="{{slide1Data_images.img1}}">\n' +
    '                    <div ng-if="selectedTheory == 0 || selectedTheory != 1" class="black-line"></div>\n' +
    '                    <div ng-if="selectedTheory == 0 || selectedTheory != 1" class="black-title">\n' +
    '                        <div class="text">{{slide1Data_1.item1}}</div>\n' +
    '                    </div>\n' +
    '                    <!--Als hij geselecteerd is-->\n' +
    '                    <img ng-if="selectedTheory == 1" class="orange-cirkel-img" src="{{slide1Data_images.img1}}">\n' +
    '                    <div ng-if="selectedTheory == 1" class="orange-line"></div>\n' +
    '                    <div ng-if="selectedTheory == 1" class="orange-title">\n' +
    '                        <div class="text">{{slide1Data_1.item1}}</div>\n' +
    '                    </div>\n' +
    '                    <div ng-if="selectedTheory == 1" class="orange-text">\n' +
    '                        <div class="rect"></div>{{slide1Data_2.item1}}</div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="container deel2" ng-click="selectTheory(2)">\n' +
    '                <div class="container-content">\n' +
    '                    <img ng-if="selectedTheory == 0 || selectedTheory != 2" class="black-cirkel-img" src="{{slide1Data_images.img2}}">\n' +
    '                    <div ng-if="selectedTheory == 0 || selectedTheory != 2" class="black-line"></div>\n' +
    '                    <div ng-if="selectedTheory == 0 || selectedTheory != 2" class="black-title">\n' +
    '                        <div class="text">{{slide1Data_1.item2}}</div>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <img ng-if="selectedTheory == 2" class="orange-cirkel-img" src="{{slide1Data_images.img2}}">\n' +
    '                    <div ng-if="selectedTheory == 2" class="orange-line"></div>\n' +
    '                    <div ng-if="selectedTheory == 2" class="orange-title">\n' +
    '                        <div class="text">{{slide1Data_1.item2}}</div>\n' +
    '                    </div>\n' +
    '                    <div ng-if="selectedTheory == 2" class="orange-text">\n' +
    '                        <div class="rect"></div>{{slide1Data_2.item2}}</div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="container deel3" ng-click="selectTheory(3)">\n' +
    '                <div class="container-content">\n' +
    '                    <img ng-if="selectedTheory == 0 || selectedTheory != 3" class="black-cirkel-img" src="{{slide1Data_images.img3}}">\n' +
    '                    <div ng-if="selectedTheory == 0 || selectedTheory != 3" class="black-line"></div>\n' +
    '                    <div ng-if="selectedTheory == 0 || selectedTheory != 3" class="black-title">\n' +
    '                        <div class="text">{{slide1Data_1.item3}}</div>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <img ng-if="selectedTheory == 3" class="orange-cirkel-img" src="{{slide1Data_images.img3}}">\n' +
    '                    <div ng-if="selectedTheory == 3" class="orange-line"></div>\n' +
    '                    <div ng-if="selectedTheory == 3" class="orange-title">\n' +
    '                        <div class="text">{{slide1Data_1.item3}}</div>\n' +
    '                    </div>\n' +
    '                    <div ng-if="selectedTheory == 3" class="orange-text">\n' +
    '                        <div class="rect"></div>{{slide1Data_2.item3}}</div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="content" ng-if="buttonTablet">\n' +
    '            <div class="container deel1-tablet" ng-click="selectTheory(1)">\n' +
    '                <div class="container-content">\n' +
    '                    <!--Als hij niet geselecteerd is-->\n' +
    '                    <img ng-if="selectedTheory == 0 || selectedTheory != 1" class="black-cirkel-img" src="{{slide1Data_images.img1}}">\n' +
    '                    <div ng-if="selectedTheory == 0 || selectedTheory != 1" class="black-line"></div>\n' +
    '                    <div ng-if="selectedTheory == 0 || selectedTheory != 1" class="black-title">\n' +
    '                        <div class="text">{{slide1Data_1.item1}}</div>\n' +
    '                    </div>\n' +
    '                    <!--Als hij geselecteerd is-->\n' +
    '                    <img ng-if="selectedTheory == 1" class="orange-cirkel-img" src="{{slide1Data_images.img1}}">\n' +
    '                    <div ng-if="selectedTheory == 1" class="orange-line"></div>\n' +
    '                    <div ng-if="selectedTheory == 1" class="orange-title">\n' +
    '                        <div class="text">{{slide1Data_1.item1}}</div>\n' +
    '                    </div>\n' +
    '                    <div ng-if="selectedTheory == 1" class="orange-text">\n' +
    '                        <div class="rect"></div>{{slide1Data_2.item1}}</div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="container deel2-tablet" ng-click="selectTheory(2)">\n' +
    '                <div class="container-content">\n' +
    '                    <img ng-if="selectedTheory == 0 || selectedTheory != 2" class="black-cirkel-img" src="{{slide1Data_images.img2}}">\n' +
    '                    <div ng-if="selectedTheory == 0 || selectedTheory != 2" class="black-line"></div>\n' +
    '                    <div ng-if="selectedTheory == 0 || selectedTheory != 2" class="black-title">\n' +
    '                        <div class="text">{{slide1Data_1.item2}}</div>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <img ng-if="selectedTheory == 2" class="orange-cirkel-img" src="{{slide1Data_images.img2}}">\n' +
    '                    <div ng-if="selectedTheory == 2" class="orange-line"></div>\n' +
    '                    <div ng-if="selectedTheory == 2" class="orange-title">\n' +
    '                        <div class="text">{{slide1Data_1.item2}}</div>\n' +
    '                    </div>\n' +
    '                    <div ng-if="selectedTheory == 2" class="orange-text">\n' +
    '                        <div class="rect"></div>{{slide1Data_2.item2}}</div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="container deel3-tablet" ng-click="selectTheory(3)">\n' +
    '                <div class="container-content">\n' +
    '                    <img ng-if="selectedTheory == 0 || selectedTheory != 3" class="black-cirkel-img" src="{{slide1Data_images.img3}}">\n' +
    '                    <div ng-if="selectedTheory == 0 || selectedTheory != 3" class="black-line"></div>\n' +
    '                    <div ng-if="selectedTheory == 0 || selectedTheory != 3" class="black-title">\n' +
    '                        <div class="text">{{slide1Data_1.item3}}</div>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <img ng-if="selectedTheory == 3" class="orange-cirkel-img" src="{{slide1Data_images.img3}}">\n' +
    '                    <div ng-if="selectedTheory == 3" class="orange-line"></div>\n' +
    '                    <div ng-if="selectedTheory == 3" class="orange-title">\n' +
    '                        <div class="text">{{slide1Data_1.item3}}</div>\n' +
    '                    </div>\n' +
    '                    <div ng-if="selectedTheory == 3" class="orange-text">\n' +
    '                        <div class="rect"></div>{{slide1Data_2.item3}}</div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <!--Derde scherm-->\n' +
    '    <div class="third-screen" ng-if="opdr3StateR == \'2\'">\n' +
    '        <div class="titel">WETENSCHAPPERS</div>\n' +
    '\n' +
    '        <div class="blobs" ng-repeat="blob in _blobs track by $index">\n' +
    '            <div class="blob-grey" ng-if="$index != opdr3StateR"></div>\n' +
    '            <div class="blob-white" ng-if="$index == opdr3StateR"></div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-if="selectedTheory == \'3\'">\n' +
    '            <div class="text-area">\n' +
    '\n' +
    '                <div class="first-text">\n' +
    '                    DE THEORIE VAN ELEKTRISCHE VLOEISTOFFEN\n' +
    '                </div>\n' +
    '                <div class="second-text">\n' +
    '                    Veel wetenschappers in de 18e eeuw dachten dat er twee soorten ladingsvloeistoffen waren: een positieve en een negatieve (ook wel ‘harsachtig’ en ‘glasachtig’ genoemd). Gewone voorwerpen hadden evenveel van beide. Een overschot van een van de twee zorgde\n' +
    '                    voor lading. Andere wetenschappers, waaronder Benjamin Franklin en Martinus van Marum, dachten dat er maar één soort vloeistof was, waarvan je een tekort of teveel kunt hebben.\n' +
    '                </div>\n' +
    '                <div class="third-text">\n' +
    '                    Welke argumenten kun je als voor- of tegenstander van deze theorie aanvoeren? <br><br> Hoe is deze theorie in de loop der tijd herzien, denk je?\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-if="selectedTheory == \'2\'">\n' +
    '            <!-- <div class="background_2"></div> -->\n' +
    '            <div class="text-area">\n' +
    '                <div class="first-text">\n' +
    '                    DE THEORIE VAN FLOGISTON\n' +
    '                </div>\n' +
    '                <div class="second-text">\n' +
    '                    Deze theorie stelde dat bij alle verbrandingsreacties een bepaalde stof – flogiston – betrokken was. Hoe meer flogiston er aanwezig was in een stof, hoe beter die zou branden. Bij het verbranden komt de flogiston vervolgens vrij.\n' +
    '                </div>\n' +
    '                <div class="third-text">\n' +
    '                    Welke argumenten kun je als voor- of tegenstander van deze theorie aanvoeren? <br><br> Hoe is deze theorie in de loop der tijd herzien, denk je?\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-if="selectedTheory == \'1\'">\n' +
    '            <!-- <div class="background_3"></div> -->\n' +
    '            <div class="text-area">\n' +
    '\n' +
    '                <div class="first-text">\n' +
    '                    DE THEORIE VAN DIERLIJKE ELEKTRICITEIT\n' +
    '                </div>\n' +
    '                <div class="second-text">\n' +
    '                    Galvani zag dat spieren in de poot van een kikker samentrokken als hij ze aanraakte met verschillende metalen. Er ontstonden zelfs vonken bij! Hij concludeerde dat de spier de bron van elektriciteit was en dat deze elektriciteit anders was dan “gewone”\n' +
    '                    elektriciteit.\n' +
    '                </div>\n' +
    '                <div class="third-text">\n' +
    '                    Welke argumenten kun je als voor- of tegenstander van deze theorie aanvoeren? <br><br> Hoe is deze theorie in de loop der tijd herzien, denk je?\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <!--Vierde scherm-->\n' +
    '    <div class="fourth-screen" ng-if="opdr3StateR == \'3\'">\n' +
    '        <div class="titel">WETENSCHAPPERS</div>\n' +
    '\n' +
    '        <div class="blobs" ng-repeat="blob in _blobs track by $index">\n' +
    '            <div class="blob-grey" ng-if="$index != opdr3StateR"></div>\n' +
    '            <div class="blob-white" ng-if="$index == opdr3StateR"></div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-if="selectedTheory == \'1\'">\n' +
    '            <div class="content">\n' +
    '                <div class="titel-sub">Hieronder staan de argumenten voor en tegen deze theorie. </br> Hadden jullie hetzelfde bedacht?</div>\n' +
    '                <div class="voor">\n' +
    '                    <img class="thumb-voor" src="{{slide1Data_3.handvoor}}">\n' +
    '                    <div class="title-voor">ARGUMENTEN VOOR</div>\n' +
    '                    <div class="black-line"></div>\n' +
    '                    <div class="text-voor">De experimenten van Galvani werden herhaald met verschillende dieren, waarbij de spieren zich steeds samentrokken.</div>\n' +
    '                </div>\n' +
    '                <div class="tegen">\n' +
    '                    <img class="thumb-tegen" src="{{slide1Data_3.handtegen}}">\n' +
    '                    <div class="title-tegen">ARGUMENTEN TEGEN</div>\n' +
    '                    <div class="black-line"></div>\n' +
    '                    <div class="text-tegen">Volta herhaalde het experiment, maar hij verving de spier door papier dat in een zoutoplossing was geweekt. Hij zag dat hier ook elektriciteit stroomde: de spier kon dus niet de bron van elektriciteit zijn! Hij concludeerde dat de\n' +
    '                        combinatie van twee metalen ervoor zorgde dat stroom ging lopen. Op basis hiervan ontwikkelde hij de Zuil van Volta.</div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '        <div ng-if="selectedTheory == \'2\'">\n' +
    '            <div class="content">\n' +
    '                <div class="titel-sub">Hieronder staan de argumenten voor en tegen deze theorie. </br> Hadden jullie hetzelfde bedacht?</div>\n' +
    '                <div class="voor">\n' +
    '                    <img class="thumb-voor" src="{{slide1Data_3.handvoor}}">\n' +
    '                    <div class="title-voor">ARGUMENTEN VOOR</div>\n' +
    '                    <div class="black-line"></div>\n' +
    '                    <div class="text-voor tablet" ng-if="buttonTablet">De theorie was gebaseerd op het oudere idee dat alles uit vier elementen bestond (water, aarde, lucht en vuur). De theorie werd niet ondersteund door experimenten maar was vooral het gevolg van redenering.</div>\n' +
    '                    <div class="text-voor" ng-if="buttonPC">De theorie was gebaseerd op het oudere idee dat alles uit vier elementen bestond (water, aarde, lucht en vuur). De theorie werd niet ondersteund door experimenten maar was vooral het gevolg van redenering.</div>\n' +
    '                </div>\n' +
    '                <div class="tegen">\n' +
    '                    <img class="thumb-tegen" src="{{slide1Data_3.handtegen}}">\n' +
    '                    <div class="title-tegen">ARGUMENTEN TEGEN</div>\n' +
    '                    <div class="black-line"></div>\n' +
    '                    <div class="text-tegen">Volgens de theorie zou bij verbranding van een stof het gewicht moeten afnemen, omdat er flogiston ontsnapt. Proeven lieten echter zien dat dit niet zo was. Bovendien was de waarneming dat verbranding in een afgesloten ruimte na een\n' +
    '                        tijd stopt moeilijk te verklaren met deze theorie.</div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-if="selectedTheory == \'3\'">\n' +
    '            <div class="content">\n' +
    '                <div class="titel-sub">Hieronder staan de argumenten voor en tegen deze theorie. </br> Hadden jullie hetzelfde bedacht?</div>\n' +
    '                <div class="voor">\n' +
    '                    <img class="thumb-voor" src="{{slide1Data_3.handvoor}}">\n' +
    '                    <div class="title-voor">ARGUMENTEN VOOR</div>\n' +
    '                    <div class="black-line"></div>\n' +
    '                    <div class="text-voor tablet" ng-if="buttonTablet">Het was duidelijk dat er twee soorten ladingen waren. Voorstanders baseerden het idee van twee soorten vloeistof hierop.</div>\n' +
    '                    <div class="text-voor" ng-if="buttonPC">Het was duidelijk dat er twee soorten ladingen waren. Voorstanders baseerden het idee van twee soorten vloeistof hierop.</div>\n' +
    '                </div>\n' +
    '                <div class="tegen">\n' +
    '                    <img class="thumb-tegen" src="{{slide1Data_3.handtegen}}">\n' +
    '                    <div class="title-tegen">ARGUMENTEN TEGEN</div>\n' +
    '                    <div class="black-line"></div>\n' +
    '                    <div class="text-tegen">Als er twee soorten vloeistof waren, dan zouden ontladingen van de elektriseermachine symmetrisch moeten zijn. De vonk zou van beide kanten moeten komen. Maar observaties van de ontladingen van de elektriseermachine lieten zien dat\n' +
    '                        dit niet zo was.</div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="fifth-screen" ng-if="opdr3StateR == \'4\'">\n' +
    '        <div class="titel">WETENSCHAPPERS</div>\n' +
    '        <div class="blobs" ng-repeat="blob in _blobs track by $index">\n' +
    '            <div class="blob-grey" ng-if="$index != opdr3StateR"></div>\n' +
    '            <div class="blob-white" ng-if="$index == opdr3StateR"></div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-if="selectedTheory == \'1\'">\n' +
    '            <div class="text-area">\n' +
    '                <div class="second-text">\n' +
    '                    HOE IS DE THEORIE IN DE LOOP VAN DE TIJD HERZIEN?\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="first-text">\n' +
    '                    Volta stelde vast dat een combinatie van twee metalen elektriciteit kan produceren, ook zonder spierweefsel. Oorspronkelijk gebruikte Volta een reeks schalen met zout- oplossing, die hij met elkaar verbond door een metalen boogje. Dit bestond aan de ene\n' +
    '                    kant uit koper en aan de andere kant uit zink, tin of aluminium. Later gebruikte hij een kolom van afwisselend koperen en zinken plaatjes, gescheiden door vilt dat in een zoutoplossing was gedrenkt: de Zuil van Volta. Zijn uitvinding\n' +
    '                    kreeg de naam\n' +
    '                    <span class="schuin">batterij</span>.\n' +
    '\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-if="selectedTheory == \'2\'">\n' +
    '            <div class="text-area">\n' +
    '                <div class="second-text">\n' +
    '                    HOE IS DE THEORIE IN DE LOOP VAN DE TIJD HERZIEN?\n' +
    '                </div>\n' +
    '                <div class="first-text">\n' +
    '                    Verbranding is een reactie tussen een brandstof en een oxidator (meestal zuurstofgas), waarbij warmte en licht ontstaat in de vorm van een vlam. Verbranding kan zowel met vaste, vloeibare als gasvormige brandstoffen optreden. Volledige verbranding van\n' +
    '                    een stof houdt in dat de stof zich met de zuurstof verbindt. Dit betekent dat de moleculen van de brandstof geheel in atomen worden gesplitst. Hierbij verbindt ieder atoom zich met zoveel atomen van de oxidator als voor dat type atoom\n' +
    '                    mogelijk is.\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-if="selectedTheory == \'3\'">\n' +
    '            <div class="text-area">\n' +
    '                <div class="second-text">\n' +
    '                    HOE IS DE THEORIE IN DE LOOP VAN DE TIJD HERZIEN?\n' +
    '                </div>\n' +
    '                <div class="first-text">\n' +
    '                    In het huidige natuurkundige begrip hebben beide partijen (deels) gelijk: de elektronen stromen in een metaal als een vloeistof en kunnen daarbij als “elektrische vloeistof” worden gezien. In een geïoniseerd gas kunnen ook de positieve ladingen stromen.\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<!-- first screen -->\n' +
    '<div class="placeholder-button" ng-if="opdr3StateR == \'0\'">\n' +
    '    <div class="buttons" ng-if="buttonPC">\n' +
    '        <button ng-click="goPrev(\'/opdracht_2\')">TERUG</button>\n' +
    '        <button ng-click="changeState(\'1\')">VERDER</button>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<!-- second screen -->\n' +
    '<div class="placeholder-button" ng-if="opdr3StateR == \'1\'">\n' +
    '    <div class="buttons" ng-if="buttonPC">\n' +
    '        <button ng-click="changeState(\'0\')">TERUG</button>\n' +
    '        <button ng-click="changeState(\'2\')" ng-if="selectedTheory != 0">VERDER</button>\n' +
    '        <button ng-click="" ng-if="selectedTheory == 0" class="greyed-out">VERDER</button>\n' +
    '    </div>\n' +
    '\n' +
    '</div>\n' +
    '<!-- thrid screen -->\n' +
    '<div class="placeholder-button" ng-if="opdr3StateR == \'2\'">\n' +
    '    <div class="buttons" ng-if="buttonPC">\n' +
    '        <button ng-click="changeState(\'1\')">TERUG</button>\n' +
    '        <button ng-click="changeState(\'3\')">VERDER</button>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<!-- fourth screen -->\n' +
    '\n' +
    '<div class="placeholder-button" ng-if="opdr3StateR == \'3\'">\n' +
    '    <div class="buttons" ng-if="buttonPC">\n' +
    '        <button ng-click="changeState(\'2\')">TERUG</button>\n' +
    '        <button ng-click="changeState(\'4\')">VERDER</button>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<!-- fifth screen -->\n' +
    '<div class="placeholder-button" ng-if="opdr3StateR == \'4\'">\n' +
    '    <div class="buttons" ng-if="buttonPC">\n' +
    '        <button ng-click="changeState(\'3\')">TERUG</button>\n' +
    '        <button ng-click="goNext(\'/opdracht_4\')">VERDER</button>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/opdracht_4.html',
    '<!--Eerste scherm-->\n' +
    '<div class="background-opd4_1" ng-if="opdr4StateR == \'0\'"></div>\n' +
    '<div class="background-opd4_1" ng-if="opdr4StateR == \'1\'"></div>\n' +
    '<div class="opdracht-4">\n' +
    '\n' +
    '    <div class="first-screen" ng-if="opdr4StateR == \'0\'">\n' +
    '        <div class="titel">EXPERIMENTEREN</div>\n' +
    '        <div class="blob-placeholder">\n' +
    '            <div class="blobs" ng-repeat="blob in _blobs track by $index">\n' +
    '                <div class="blob-grey" ng-if="$index != opdr4StateR"></div>\n' +
    '                <div class="blob-white" ng-if="$index == opdr4StateR"></div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="text-area">\n' +
    '            <div class="first-text">\n' +
    '                Tot eind 17e eeuw deden wetenschappers geen experimenten maar hielden zich vooral bezig met logisch nadenken. In de 18e eeuw veranderde dat: het experiment werd ‘ingevoerd’. Vanaf dat moment maakt de kennis over elektriciteit en magnetisme een enorme\n' +
    '                sprong voorwaarts. Dit empirisme, waarbij experimenten een cruciale rol spelen, vormt nog steeds de kern van de moderne wetenschappelijke methode.\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '    <!--Tweede scherm-->\n' +
    '    <div class="second-screen" ng-if="opdr4StateR == \'1\'">\n' +
    '        <div class="titel">EXPERIMENTEREN</div>\n' +
    '        <div class="blob-placeholder">\n' +
    '            <div class="blobs" ng-repeat="blob in _blobs track by $index">\n' +
    '                <div class="blob-grey" ng-if="$index != opdr4StateR"></div>\n' +
    '                <div class="blob-white" ng-if="$index == opdr4StateR"></div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="text-area">\n' +
    '            <div class="third-text">\n' +
    '                WAT ZIJN DE VOORDELEN VAN HET DOEN VAN EXPERIMENTEN?<br><br>\n' +
    '            </div>\n' +
    '            <div class="first-text">\n' +
    '                Bespreek het met je groepsgenoten en bedenk minstens twee voordelen.\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '    <!--Derde scherm-->\n' +
    '    <div class="third-screen" ng-if="opdr4StateR == \'2\'">\n' +
    '        <div class="titel">EXPERIMENTEREN</div>\n' +
    '        <div class="blob-placeholder">\n' +
    '            <div class="blobs" ng-repeat="blob in _blobs track by $index">\n' +
    '                <div class="blob-grey" ng-if="$index != opdr4StateR"></div>\n' +
    '                <div class="blob-white" ng-if="$index == opdr4StateR"></div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="content">\n' +
    '            <div class="title">Mogelijke antwoorden:</div>\n' +
    '            <div class="text">\n' +
    '                Met een experiment kun je toetsen of een hypothese klopt.\n' +
    '\n' +
    '            </div>\n' +
    '            <div class="black-line"></div>\n' +
    '            <div class="text">\n' +
    '                Met een experiment kun je toetsen onder welke omstandigheden een hypothese klopt.\n' +
    '            </div>\n' +
    '            <div class="black-line"></div>\n' +
    '            <div class="text">\n' +
    '                Door metingen uit te voeren tijdens experimenten kun je een wet proberen af te leiden.\n' +
    '            </div>\n' +
    '            <div class="black-line"></div>\n' +
    '            <div class="text">\n' +
    '                Met een experiment kun je toetsen of een voorspelling klopt.\n' +
    '            </div>\n' +
    '            <div class="black-line"></div>\n' +
    '            <div class="text">\n' +
    '                Door te experimenteren doe je nieuwe inzichten op en vergroot je je kennis.\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<!-- First -->\n' +
    '<div class="placeholder-button" ng-if="opdr4StateR == \'0\'">\n' +
    '    <div class="buttons" ng-if="buttonPC">\n' +
    '        <button ng-click="goPrev(\'/opdracht_3\')">TERUG</button>\n' +
    '        <button ng-click="changeState(\'1\')">VERDER</button>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<!-- Second -->\n' +
    '<div class="placeholder-button" ng-if="opdr4StateR == \'1\'">\n' +
    '    <div class="buttons" ng-if="buttonPC">\n' +
    '        <button ng-click="changeState(\'0\')">TERUG</button>\n' +
    '        <button ng-click="changeState(\'2\')">VERDER</button>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<!-- Third -->\n' +
    '<div class="placeholder-button" ng-if="opdr4StateR == \'2\'">\n' +
    '    <div class="buttons" ng-if="buttonPC">\n' +
    '        <button ng-click="changeState(\'1\')">TERUG</button>\n' +
    '        <button ng-click="goNext(\'/eindscherm\')">VERDER</button>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/gui/footer.html',
    '<div class="footer">\n' +
    '    \n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/gui/header.html',
    '<header class="menu-header">\n' +
    'HOI\n' +
    '</header>');
}]);
})();
