app.service("mainService", function($http) {
    // main setttings

    this.opdr1state = '-1';
    this.opdr2state = '-1';
    this.opdr3state = '-1';
    this.opdr4state = '-1';

    this.data = {};
    this.src = ""
        // INTRODUCTIE
    this.vidSrc1_Intro = "https://www.youtube.com/embed/NpEaa2P7qZI?autoplay=1&cc_load_policy=1&rel=0"
    this.vidSrc2_Intro = "https://www.youtube.com/embed/NpEaa2P7qZI?autoplay=1&cc_load_policy=1&rel=0"

    // OPDRACHT 1
    // $scope.URLextra = "";
    //SLIDE 1
    this.vidSrc1_Op1_1 = "https://www.youtube.com/embed/3LsBse8WKm4?end=30&autoplay=1&cc_load_policy=1&rel=0"
    this.vidSrc1_Op1_2 = "https://www.youtube.com/embed/3LsBse8WKm4?autoplay=1&cc_load_policy=1&rel=0"
    this.vidSrc1_Op1_3 = "https://www.youtube.com/embed/3LsBse8WKm4?autoplay=1&cc_load_policy=1&rel=0"
    this.vidSrc1_Op1_4 = "https://www.youtube.com/embed/3LsBse8WKm4?autoplay=1&cc_load_policy=1&rel=0"
    this.vidSrc1_Op1_5 = "https://www.youtube.com/embed/3LsBse8WKm4?autoplay=1&cc_load_policy=1&rel=0"
    this.vidSrc1_Op1_6 = "https://www.youtube.com/embed/3LsBse8WKm4?autoplay=1&cc_load_policy=1&rel=0"

    //SLIDE 2
    this.vidSrc2_Op1_1 = "https://www.youtube.com/embed/3LsBse8WKm4?start=30&autoplay=1&rel=0"
    this.vidSrc2_Op1_2 = "https://www.youtube.com/embed/NpEaa2P7qZI?autoplay=1&cc_load_policy=1&rel=0"
    this.vidSrc2_Op1_3 = "https://www.youtube.com/embed/Qy3KTm1afPE?autoplay=1&cc_load_policy=1&rel=0"
    this.vidSrc2_Op1_4 = "https://www.youtube.com/embed/NpEaa2P7qZI?autoplay=1&cc_load_policy=1&rel=0"
    this.vidSrc2_Op1_5 = "https://www.youtube.com/embed/EBgb8lFtHa8?autoplay=1&cc_load_policy=1&rel=0"
    this.vidSrc2_Op1_6 = "https://www.youtube.com/embed/JXQLYgNWp1Q?autoplay=1&cc_load_policy=1&rel=0"

    this.selectedType = '0'

    this.optiondata = {
        row1: {
            item1: {
                img: "./assets/images/opdracht1/assets/1_.png",
                title: "Elektrische vissen"
            },
            item2: {
                img: "./assets/images/opdracht1/assets/2_.png",
                title: "Poollicht"
            },
            item3: {
                img: "./assets/images/opdracht1/assets/7_.png",
                title: "Bliksem"
            }
        },
        row2: {
            item1: {
                img: "./assets/images/opdracht1/assets/3_.png",
                title: "Magnetische stenen"
            },
            item2: {
                img: "./assets/images/opdracht1/assets/4_.png",
                title: "Sint Elmusvuur"
            },
            item3: {
                img: "./assets/images/opdracht1/assets/5_.png",
                title: "Statische elektriciteit"
            }
        },
        // row3: {

        // }
    };

    // OPDRACHT 2 ---------------------------------------------------------------------------

    //SLIDE 1
    // --
    //SLIDE 2

    this.option2data = {
        item1: {
            img: "./assets/images/opdracht2/assets/1.png",
            title: "Lorentz:",
            content: "HUHEUHEUHE",
            lastdrop: 0
        },
        item2: {
            img: "./assets/images/opdracht2/assets/2.png",
            title: "Faraday:",
            content: "HUHEUHEUHE",
            lastdrop: 0
        },
        item3: {
            img: "./assets/images/opdracht2/assets/3.png",
            title: "Ampère:",
            content: "HUHEUHEUHE",
            lastdrop: 0
        },
        item4: {
            img: "./assets/images/opdracht2/assets/4.png",
            title: "Volta:",
            content: "HUHEUHEUHE",
            lastdrop: 0
        },
        item5: {
            img: "./assets/images/opdracht2/assets/5.png",
            title: "Galvani:",
            content: "HUHEUHEUHE",
            lastdrop: 0
        },
        item6: {
            img: "./assets/images/opdracht2/assets/6.png",
            title: "Ørsted:",
            content: "HUHEUHEUHE",
            lastdrop: 0
        },

    };
    this.answers = [{
        content: "Een formule die de kracht beschrijft die een bewegende lading ondervindt in een elektrisch én magnetisch veld",
        title: "Lorentz",
        currentPosition: 0
    }, {
        content: "Met een magnetisch veld kan een elektrische stroom opgewekt worden. Dit is het begin van de elektromotor.",
        title: "Faraday",
        currentPosition: 0
    }, {
        content: "De wet die de kracht beschrijft tussen twee draden waar stroom doorheen loopt.",
        title: "Ampère",
        currentPosition: 0
    }, {
        content: "Stroom kan opgewekt worden door een combinatie van twee verschillende metalen en een zout- of zuuroplossing.",
        title: "Volta",
        currentPosition: 0
    }, {
        content: "Spieren in de poot van een kikker trekken zich samen als hij door twee verschillende metalen wordt aangeraakt.",
        title: "Galvani",
        currentPosition: 0
    }, {
        content: "Een kompas slaat uit in de buurt van een draad waar stroom doorheen loopt.",
        title: "Ørsted",
        currentPosition: 0
    }]

    this.tijdlijn = {
        tijdlijn1: {
            img: "./assets/images/opdracht2/assets/tijdlijn-1.png",
            title: "Ørsted:"
        },
        tijdlijn2: {
            img: "./assets/images/opdracht2/assets/tijdlijn-2.png",
            title: "Ørsted:"
        },
        tijdlijn2mobile: {
            img: "./assets/images/opdracht2/assets/tijdlijn-2-tablet.png",
            title: "Ørsted:"
        }
    };


    //SLIDE 3
    this.screenThreeScientists = [{
            img: "./assets/images/opdracht2/assets/4.png",
            title: "VOLTA",
            currentPosition: 0

        },
        {

            img: "./assets/images/opdracht2/assets/1.png",
            title: "LORENTZ",
            currentPosition: 0
        },
        {
            img: "./assets/images/opdracht2/assets/3.png",
            title: "AMPÈRE",
            currentPosition: 0
        },
        {

            img: "./assets/images/opdracht2/assets/6.png",
            title: "∅RSTED",
            currentPosition: 0
        },
        {
            img: "./assets/images/opdracht2/assets/2.png",
            title: "FARADAY",
            currentPosition: 0

        },
        {

            img: "./assets/images/opdracht2/assets/5.png",
            title: "GALVANI",
            currentPosition: 0
        },

    ];


    //SLIDE 4

    this.gebeurtenisTimeLine = [{
            img: "./assets/images/opdracht2/assets/12.png",
            title: "1e OLYMPISCHE SPELEN",
            currentPosition: 0


        },
        {
            img: "./assets/images/opdracht2/assets/11.png",
            title: "NEDERLANDSE GRONDWET",
            currentPosition: 0


        },
        {
            img: "./assets/images/opdracht2/assets/9.png",
            title: "SLAG BIJ WATERLOO",
            currentPosition: 0
        },
        {
            img: "./assets/images/opdracht2/assets/10.png",
            title: "ONAFHANKELIJK BELGIË",
            currentPosition: 0
        },
        {
            img: "./assets/images/opdracht2/assets/7.png",
            title: "OPENING TEYLERS",
            currentPosition: 0
        },
        {
            img: "./assets/images/opdracht2/assets/8.png",
            title: "FRANSE REVOLUTIE",
            currentPosition: 0
        },

    ];

    // OPDRACHT 2 ---------------------------------------------------------------------------

    //SLIDE 1

    //SLIDE 2
    this.opdracht3Slide2_1 = {
        item1: "Dierlijke elektriciteit",
        item2: "Flogiston",
        item3: "Twee elektrische vloeistoffen"
    }
    this.opdracht3Slide2_2 = {
        item1: "Galvani zag dat spieren in de poot van een kikker samentrokken als hij ze aanraakte met verschillende metalen. Hij concludeerde dat de spier de bron van elektriciteit was en dat deze elektriciteit anders was dan “gewone” elektriciteit.",
        item2: "Deze theorie stelde dat bij alle verbrandingsreacties een bepaalde stof – flogiston – betrokken was. Hoe meer flogiston er aanwezig was, hoe beter een stof zou branden. Bij het verbranden komt de flogiston vervolgens vrij.",
        item3: "Veel wetenschappers in de 18e eeuw dachten dat er twee soorten ladingsvloeistoffen waren: een positieve en een negatieve (ook wel ‘harsachtig’ en ‘glasachtig’ genoemd). Gewone voorwerpen hadden evenveel van beide. Een overschot van een van de twee zorgde voor lading."
    }
    this.opdracht3Slide2_3 = {
            handvoor: "./assets/images/opdracht3/assets/hand-voor.png",
            handtegen: "./assets/images/opdracht3/assets/hand-tegen.png",
            img1: "./assets/images/opdracht3/assets/vloeistof.png",
            img2: "./assets/images/opdracht3/assets/flogiston.png",
            img3: "./assets/images/opdracht3/assets/kikkerbenen.png"
        }
        //SLIDE 3

    //SLIDE 4


});