app.controller('opdrachtCtrl_3', ['$scope', '$sce', '$location', 'mainService', '$rootScope', function($scope, $sce, $location, mainService, $rootScope, Api, $route, $routeParams) {
    // $scope._optionData = {};
    // $scope._optionData = mainService.optiondata;

    // $scope.vidSrc_1 = $sce.trustAsResourceUrl(mainService.vidSrc1_Op1);
    // $scope.vidSrc_2 = $sce.trustAsResourceUrl(mainService.vidSrc2_Op1);

    $rootScope._showMenu = false;
    //Algemene vars + functies

    $scope._blobs = [1, 2, 3, 4, 5];
    // $scope._displayState = 3;
    //   $scope._displayState = mainService.opdr3state;
    // $rootScope.opdr2StateR = '2';
    // $rootScope.opdr3StateR = '1';
    // $rootScope.opdr4StateR = '-1';

    $scope.changeState = function(state) {
        console.log(state);
        // $scope._displayState = state;
        $rootScope.opdr3StateR = state;
    }
    $scope.goPrev = function(path) {
        $rootScope.opdr1StateR = '-1';
        if ($rootScope.setMenu2) {
            $rootScope.opdr2StateR = '3';
        } else {
            $rootScope.opdr2StateR = '1';
        }
        $rootScope.opdr3StateR = '-1';
        $rootScope.opdr4StateR = '-1';
        $location.path(path);
    };
    $scope.goNext = function(path) {
        $rootScope.opdr1StateR = '-1';
        $rootScope.opdr2StateR = '-1';
        $rootScope.opdr3StateR = '-1';
        $rootScope.opdr4StateR = '0';
        $location.path(path);
    };

    // $rootScope.setMenu3 = false;
    // $rootScope.opdr3StateR = '0';

    //Scherm 1

    //content
    $scope.slide1Data_1 = mainService.opdracht3Slide2_1;
    $scope.slide1Data_2 = mainService.opdracht3Slide2_2;
    // $scope.slide1Data_3 = mainService.opdracht3Slide2_3;
    $scope.slide1Data_images = mainService.opdracht3Slide2_3;
    $scope.slide1Data_3 = mainService.opdracht3Slide2_3;


    //Variables
    $rootScope.selectedTheory = 0;

    $scope.selectTheory = function(select) {
        $rootScope.selectedTheory = select;
        console.log("GHUHUEUHE", select)
        $rootScope.setMenu3 = true;
    }

}]);