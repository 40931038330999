app.controller('introductieCtrl', ['$scope', '$sce', '$location', 'mainService', '$rootScope', function($scope, $sce, $location, mainService, $rootScope, Api, $route, $routeParams) {
    $scope.vidSrc_1 = $sce.trustAsResourceUrl(mainService.vidSrc1_Intro);
    $scope.vidSrc_2 = $sce.trustAsResourceUrl(mainService.vidSrc2_Intro);
    $rootScope._showMenu = false;
    $scope._displayState = 'one';
    $scope.sendCommand = function() {
        console.log("introductieCtrl");
    };
    $scope.changeState = function(state) {
        $scope._displayState = state;
    }
    $scope.go = function(path) {
        console.log("Hello World", path)
        $location.path(path);
        $rootScope.opdr1StateR = '0';

    };
    $scope.openVideoScreen1 = false;
    $scope.openVideoScreen2 = false;

    $scope.closeVideo = function() {

        $scope.openVideoScreen1 = false;
        $scope.openVideoScreen2 = false;
        console.log($scope.openVideoScreen1);

    }
    $scope.openVideo = function(number) {
        if (number == '1') {
            $scope.openVideoScreen1 = true;
        } else {
            $scope.openVideoScreen2 = true;
        }
    }
}]);