app.controller('mainCtrl', ['mainService', '$scope', '$controller', '$sce', '$location', '$rootScope', function(mainService, $scope, $controller, $sce, $location, $rootScope, Api, $route, $routeParams) {

    $rootScope.buttonPC = true;
    $rootScope.buttonTablet = false;


    // if (navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)) {
    //     console.log("Tablet")
    //     $rootScope.buttonTablet = true;
    //     $rootScope.buttonPC = false;
    // }
    // if (!navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)) {
    //     console.log("PC")
    //     $rootScope.buttonPC = true;
    //     $rootScope.buttonTablet = false;
    // }
    $scope.currentOpdr = '1';
    $scope.currentState1 = '0';
    $scope.currentState2 = '0';
    $scope.currentState3 = '0';
    $scope.currentState4 = '0';

    $rootScope.setMenu = false;
    $rootScope.setMenu2 = false;
    $rootScope.setMenu3 = false;

    $rootScope._selectedRow = 0;
    // opdracht 2
    // deel 1
    $rootScope.droppedObjects_1 = [];
    $rootScope.droppedObjects_2 = [];
    $rootScope.droppedObjects_3 = [];
    $rootScope.droppedObjects_4 = [];
    $rootScope.droppedObjects_5 = [];
    $rootScope.droppedObjects_6 = [];

    // deel 2
    $rootScope.TL_1 = [];
    $rootScope.TL_2 = [];
    $rootScope.TL_3 = [];
    $rootScope.TL_4 = [];
    $rootScope.TL_5 = [];
    $rootScope.TL_6 = [];

    // deel 3
    $rootScope.TL_1_2 = [];
    $rootScope.TL_2_2 = [];
    $rootScope.TL_3_2 = [];
    $rootScope.TL_4_2 = [];
    $rootScope.TL_5_2 = [];
    $rootScope.TL_6_2 = [];



    $rootScope.opdr1StateR = '-1';
    $rootScope.opdr2StateR = '-1';
    $rootScope.opdr3StateR = '-1';
    $rootScope.opdr4StateR = '-1';

    $rootScope._showMenu = false;
    $scope.menuFunction = function(statement) {
        // $scope.showMenu = true
        // console.log(statement);
        if (statement == "open") {
            $rootScope._showMenu = true;
        } else {
            $rootScope._showMenu = false;
        }
        console.log("Hello world", $rootScope._showMenu, statement);
    }
    $scope.goToState = function() {
        $location.path("/");
    }


    // if (navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)) {
    //     console.log("!?!?!")
    // }
    $scope.changeState = function(opdr, state, path) {
        console.log(opdr, state, path, "Hey!")
            // $location.path('/introductie');
            // return;

        $rootScope.opdr1StateR = '-1';
        $rootScope.opdr2StateR = '-1';
        $rootScope.opdr3StateR = '-1';
        $rootScope.opdr4StateR = '-1';
        switch (opdr) {
            case 'opdr1':
                {
                    $rootScope.opdr1StateR = state;
                    $location.path(path);
                    break;
                }
            case 'opdr2':
                {
                    $rootScope.opdr2StateR = state;
                    $location.path(path);
                    break;

                }
            case 'opdr3':
                {
                    $rootScope.opdr3StateR = state;
                    $location.path(path);
                    break;

                }
            case 'opdr4':
                {
                    $rootScope.opdr4StateR = state;
                    $location.path(path);
                    break;

                }
        }
        // if (opdr = 'opdr1') {
        //     // mainService.opdr1state = state;

        //     // $scope.currentOpdr = '1';
        //     // $scope.currentState1 = state;
        // };
        // if (opdr = 'opdr2') {
        //     console.log("?")

        //     // $scope.currentOpdr = '1';
        //     // $scope.currentState1 = state;
        // };
        // if (opdr = 'opdr3') {

        //     // $scope.currentOpdr = '1';
        //     // $scope.currentState1 = state;

        // };
        // if (opdr = 'opdr4') {

        //     // $scope.currentOpdr = '1';
        //     // $scope.currentState1 = state;
        // };


    };




}]);