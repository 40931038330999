app.controller('opdrachtCtrl_1', [ '$scope', '$sce', '$location', 'mainService', '$rootScope', function ($scope, $sce,  $location, mainService, $rootScope,Api,$route, $routeParams) 
{
    $scope._optionData = {};
    $scope._optionData = mainService.optiondata;

    $scope.vidSrc_1 = $sce.trustAsResourceUrl(mainService.vidSrc1_Op1);
    $scope.vidSrc_2 = $sce.trustAsResourceUrl(mainService.vidSrc2_Op1);
    $scope._blobs = [1,2,3,4];

    $rootScope._selectedRow = 0;
    $rootScope._selectedCirkel = 0;

    $scope._displayState = '0';
    $scope.changeState = function(state){
        $scope._displayState = state;
        console.log($scope._optionData);
    }
    $scope.go = function(path) {
        $location.path(path);
      
    };
    $scope.selectCirkel = function(rowNumber, indexNumber){
        console.log(rowNumber, indexNumber);
        $rootScope._selectedRow = rowNumber;
        $rootScope._selectedCirkel = indexNumber;
    }
}]);