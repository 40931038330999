app.controller('opdrachtCtrl_4', ['$scope', '$sce', '$location', 'mainService', '$rootScope', function($scope, $sce, $location, mainService, $rootScope, Api, $route, $routeParams) {
    // $scope._optionData = {};
    // $scope._optionData = mainService.optiondata;

    // $scope.vidSrc_1 = $sce.trustAsResourceUrl(mainService.vidSrc1_Op1);
    // $scope.vidSrc_2 = $sce.trustAsResourceUrl(mainService.vidSrc2_Op1);
    $scope._blobs = [1, 2, 3];

    $rootScope._showMenu = false;
    //   $scope._displayState = mainService.opdr4state;
    // $rootScope.opdr2StateR = '-1';
    // $rootScope.opdr3StateR = '-1';
    // $rootScope.opdr4StateR = '0';

    $scope.changeState = function(state) {
            console.log(state);
            // $scope._displayState = state;
            $rootScope.opdr4StateR = state;
        }
        // $rootScope.opdr4StateR = '0';
    $scope.goPrev = function(path) {
        $rootScope.opdr1StateR = '-1';
        $rootScope.opdr2StateR = '-1';
        if ($rootScope.setMenu3) {
            $rootScope.opdr3StateR = '4';
        } else {
            $rootScope.opdr3StateR = '1';
        }
        $rootScope.opdr4StateR = '-1';
        $location.path(path);
    };
    $scope.goNext = function(path) {
        $rootScope.opdr1StateR = '-1';
        $rootScope.opdr2StateR = '-1';
        $rootScope.opdr3StateR = '-1';
        $rootScope.opdr4StateR = '-1';
        $location.path(path);
    };

    // $scope.changeOldState = function() {
    //     console.log("?")

    // }
}]);