app.controller('opdrachtCtrl_2', ['$scope', '$sce', '$location', 'mainService', '$rootScope', function($scope, $sce, $location, mainService, $rootScope, Api, $route, $routeParams) {

    // Scherm 2
    $scope.scientistsTimeLine = mainService.screenThreeScientists;

    //Answers zetten
    $scope.answers = mainService.answers;
    $rootScope._showMenu = false;

    $scope.scientistData = {};

    //Als de drag compleet is, dus niet de drop.
    $scope.onDragComplete = function(data, evt) {
        console.log("drag success, data:", data);
    }


    $scope.removeLastPos = function(target, data) {
        switch (target) {
            // Vorige array leeg maken
            case 1:
                {
                    var index = $rootScope.droppedObjects_1.indexOf(data);
                    if (index != -1) {
                        $rootScope.droppedObjects_1 = [];
                    }

                    break;
                }
            case 2:
                {
                    var index = $rootScope.droppedObjects_2.indexOf(data);
                    if (index != -1) {
                        $rootScope.droppedObjects_2 = [];
                    }

                    break;
                }
            case 3:
                {
                    var index = $rootScope.droppedObjects_3.indexOf(data);
                    if (index != -1) {
                        $rootScope.droppedObjects_3 = [];
                    }

                    break;
                }
            case 4:
                {
                    var index = $rootScope.droppedObjects_4.indexOf(data);
                    if (index != -1) {
                        $rootScope.droppedObjects_4 = [];
                    }

                    break;
                }
            case 5:
                {
                    var index = $rootScope.droppedObjects_5.indexOf(data);
                    if (index != -1) {
                        $rootScope.droppedObjects_5 = [];
                    }

                    break;
                }
            case 6:
                {
                    var index = $rootScope.droppedObjects_6.indexOf(data);
                    if (index != -1) {
                        $rootScope.droppedObjects_6 = [];
                    }

                    break;
                }
        }
    }



    $scope.onDropComplete = function(target, data, evt) {
        switch (target) {
            case 1:
                {
                    // Object uit de answers array als hij er nog in zit
                    var index = $scope.answers.indexOf(data);
                    if (data.currentPosition == 1) {
                        return;
                    }
                    if (index != -1) {
                        $scope.answers.splice(index, 1);
                    }
                    // Als er al een object in zit, dit verplaatsen naar answers array
                    if ($rootScope.droppedObjects_1.length >= 1) {
                        $rootScope.droppedObjects_1[0].currentPosition = 0;
                        $scope.answers.push($rootScope.droppedObjects_1[0]);
                        $rootScope.droppedObjects_1 = [];
                    }
                    // Het object er daadwerkelijk in zetten
                    $rootScope.droppedObjects_1.push(data);
                    if (data.currentPosition != 0) {
                        $scope.removeLastPos(data.currentPosition, data);
                    }
                    data.currentPosition = 1;
                    break;
                }

            case 2:
                {
                    // Object uit de answers array als hij er nog in zit
                    var index = $scope.answers.indexOf(data);
                    if (data.currentPosition == 2) {
                        return;
                    }
                    if (index != -1) {
                        $scope.answers.splice(index, 1);
                    }
                    // Als er al een object in zit, dit verplaatsen naar answers array
                    if ($rootScope.droppedObjects_2.length >= 1) {
                        $rootScope.droppedObjects_2[0].currentPosition = 0;
                        $scope.answers.push($rootScope.droppedObjects_2[0]);
                        $rootScope.droppedObjects_2 = [];
                    }
                    // Het object er daadwerkelijk in zetten
                    $rootScope.droppedObjects_2.push(data);
                    if (data.currentPosition != 0) {
                        $scope.removeLastPos(data.currentPosition, data);
                    }
                    data.currentPosition = 2;
                    break;
                }


            case 3:
                {
                    // Object uit de answers array als hij er nog in zit
                    var index = $scope.answers.indexOf(data);
                    if (data.currentPosition == 3) {
                        return;
                    }
                    if (index != -1) {
                        $scope.answers.splice(index, 1);
                    }
                    // Als er al een object in zit, dit verplaatsen naar answers array
                    if ($rootScope.droppedObjects_3.length >= 1) {
                        $rootScope.droppedObjects_3[0].currentPosition = 0;
                        $scope.answers.push($rootScope.droppedObjects_3[0]);
                        $rootScope.droppedObjects_3 = [];
                    }
                    // Het object er daadwerkelijk in zetten
                    $rootScope.droppedObjects_3.push(data);
                    if (data.currentPosition != 0) {
                        $scope.removeLastPos(data.currentPosition, data);
                    }
                    data.currentPosition = 3;
                    break;
                }


            case 4:
                {
                    // Object uit de answers array als hij er nog in zit
                    var index = $scope.answers.indexOf(data);
                    if (data.currentPosition == 4) {
                        return;
                    }
                    if (index != -1) {
                        $scope.answers.splice(index, 1);
                    }
                    // Als er al een object in zit, dit verplaatsen naar answers array
                    if ($rootScope.droppedObjects_4.length >= 1) {
                        $rootScope.droppedObjects_4[0].currentPosition = 0;
                        $scope.answers.push($rootScope.droppedObjects_4[0]);
                        $rootScope.droppedObjects_4 = [];
                    }
                    // Het object er daadwerkelijk in zetten
                    $rootScope.droppedObjects_4.push(data);
                    if (data.currentPosition != 0) {
                        $scope.removeLastPos(data.currentPosition, data);
                    }
                    data.currentPosition = 4;
                    break;
                }


            case 5:
                {
                    // Object uit de answers array als hij er nog in zit
                    var index = $scope.answers.indexOf(data);
                    if (data.currentPosition == 5) {
                        return;
                    }
                    if (index != -1) {
                        $scope.answers.splice(index, 1);
                    }
                    // Als er al een object in zit, dit verplaatsen naar answers array
                    if ($rootScope.droppedObjects_5.length >= 1) {
                        $rootScope.droppedObjects_5[0].currentPosition = 0;
                        $scope.answers.push($rootScope.droppedObjects_5[0]);
                        $rootScope.droppedObjects_5 = [];
                    }
                    // Het object er daadwerkelijk in zetten
                    $rootScope.droppedObjects_5.push(data);
                    if (data.currentPosition != 0) {
                        $scope.removeLastPos(data.currentPosition, data);
                    }
                    data.currentPosition = 5;
                    break;
                }


            case 6:
                {
                    // Object uit de answers array als hij er nog in zit
                    var index = $scope.answers.indexOf(data);
                    if (data.currentPosition == 6) {
                        return;
                    }
                    if (index != -1) {
                        $scope.answers.splice(index, 1);
                    }
                    // Als er al een object in zit, dit verplaatsen naar answers array
                    if ($rootScope.droppedObjects_6.length >= 1) {
                        $rootScope.droppedObjects_6[0].currentPosition = 0;
                        $scope.answers.push($rootScope.droppedObjects_6[0]);
                        $rootScope.droppedObjects_6 = [];
                    }
                    // Het object er daadwerkelijk in zetten
                    $rootScope.droppedObjects_6.push(data);
                    if (data.currentPosition != 0) {
                        $scope.removeLastPos(data.currentPosition, data);
                    }
                    data.currentPosition = 6;
                    break;
                }




        }
        // if (data.currentPosition != 0) {
        //     $scope.removeLastPos(data.currentPosition, data);
        // }
        // data.currentPosition = target;
        $scope.checkAnswers();
    }

















    $scope.checkAnswers = function() {
        $scope.counter = 0;
        $scope.correntAnswerCounter = 0;
        if ($rootScope.droppedObjects_1.length > 0) {
            $scope.counter++;
            if ($rootScope.droppedObjects_1[0].title == "Lorentz") {
                $scope.correntAnswerCounter++;
            }
        }
        if ($rootScope.droppedObjects_2.length > 0) {
            $scope.counter++;
            if ($rootScope.droppedObjects_2[0].title == "Faraday") {
                $scope.correntAnswerCounter++;
            }
        }
        if ($rootScope.droppedObjects_3.length > 0) {
            $scope.counter++;
            if ($rootScope.droppedObjects_3[0].title == "Ampère") {
                $scope.correntAnswerCounter++;
            }
        }
        if ($rootScope.droppedObjects_4.length > 0) {
            $scope.counter++;
            if ($rootScope.droppedObjects_4[0].title == "Volta") {
                $scope.correntAnswerCounter++;
            }
        }
        if ($rootScope.droppedObjects_5.length > 0) {
            $scope.counter++;
            if ($rootScope.droppedObjects_5[0].title == "Galvani") {
                $scope.correntAnswerCounter++;
            }
        }
        if ($rootScope.droppedObjects_6.length > 0) {
            $scope.counter++;
            if ($rootScope.droppedObjects_6[0].title == "Ørsted") {
                $scope.correntAnswerCounter++;
            }
        }
        console.log("Aantal goede antwoorden:", $scope.correntAnswerCounter);

        if ($scope.counter == 6) {
            $scope.continueCheck();
            $rootScope.setMenu2 = true;
        }
    }


    $scope.onDropCompleteInput = function(data, evt) {
        console.log("drop on input success, data:", data);
        $scope.input = data;
    }

    $scope.onDropCompleteRemove = function(data, evt) {
        console.log("drop success - remove, data:", data);
        var index = $rootScope.droppedObjects_1.indexOf(data);
        // if (index != -1)
        // $rootScope.droppedObjects_1.splice(index);
    }

    // var onDraggableEvent = function (evt, data) {
    //     console.log("128", "onDraggableEvent", evt, data);
    // }
    // $scope.$on('draggable:start', onDraggableEvent);
    // //$scope.$on('draggable:move', onDraggableEvent);
    // $scope.$on('draggable:end', onDraggableEvent);



    // Einde scherm 2



    $scope.timeLineData = mainService.tijdlijn;

    // Opdracht 2, deel 2


    $scope.onDropTLComplete = function(target, data, _event) {
        // console.log("New drop function", target, data);
        // console.log($scope.scientistsTimeLine);
        switch (target) {
            case 1:
                {
                    // Object uit de answers array als hij er nog in zit
                    var index = $scope.scientistsTimeLine.indexOf(data);
                    console.log(index);
                    if (data.currentPosition == 1) {
                        return;
                    }
                    if (index != -1) {
                        $scope.scientistsTimeLine.splice(index, 1);
                    }
                    // Als er al een object in zit, dit verplaatsen naar answers array

                    if ($rootScope.TL_1.length >= 1) {
                        $rootScope.TL_1[0].currentPosition = 0;
                        $scope.scientistsTimeLine.push($rootScope.TL_1[0]);
                        $rootScope.TL_1 = [];
                    }
                    // Het object er daadwerkelijk in zetten
                    $rootScope.TL_1.push(data);
                    if (data.currentPosition != 0) {
                        $scope.removeLastPosTL(data.currentPosition, data);
                    }
                    data.currentPosition = 1;
                    break;
                }
            case 2:
                {
                    // Object uit de answers array als hij er nog in zit
                    var index = $scope.scientistsTimeLine.indexOf(data);
                    if (data.currentPosition == 2) {
                        return;
                    }
                    if (index != -1) {
                        $scope.scientistsTimeLine.splice(index, 1);
                    }
                    // Als er al een object in zit, dit verplaatsen naar answers array
                    if ($rootScope.TL_2.length >= 1) {
                        $rootScope.TL_2[0].currentPosition = 0;
                        $scope.scientistsTimeLine.push($rootScope.TL_2[0]);
                        $rootScope.TL_2 = [];
                    }
                    // Het object er daadwerkelijk in zetten
                    $rootScope.TL_2.push(data);
                    if (data.currentPosition != 0) {
                        $scope.removeLastPosTL(data.currentPosition, data);
                    }
                    data.currentPosition = 2;
                    break;
                }
            case 3:
                {
                    // Object uit de answers array als hij er nog in zit
                    var index = $scope.scientistsTimeLine.indexOf(data);
                    if (data.currentPosition == 3) {
                        return;
                    }
                    if (index != -1) {
                        $scope.scientistsTimeLine.splice(index, 1);
                    }
                    // Als er al een object in zit, dit verplaatsen naar answers array
                    if ($rootScope.TL_3.length >= 1) {
                        $rootScope.TL_3[0].currentPosition = 0;
                        $scope.scientistsTimeLine.push($rootScope.TL_3[0]);
                        $rootScope.TL_3 = [];
                    }
                    // Het object er daadwerkelijk in zetten
                    $rootScope.TL_3.push(data);
                    if (data.currentPosition != 0) {
                        $scope.removeLastPosTL(data.currentPosition, data);
                    }
                    data.currentPosition = 3;
                    break;
                }
            case 4:
                {
                    // Object uit de answers array als hij er nog in zit
                    var index = $scope.scientistsTimeLine.indexOf(data);
                    if (data.currentPosition == 4) {
                        return;
                    }
                    if (index != -1) {
                        $scope.scientistsTimeLine.splice(index, 1);
                    }
                    // Als er al een object in zit, dit verplaatsen naar answers array
                    if ($rootScope.TL_4.length >= 1) {
                        $rootScope.TL_4[0].currentPosition = 0;
                        $scope.scientistsTimeLine.push($rootScope.TL_4[0]);
                        $rootScope.TL_4 = [];
                    }
                    // Het object er daadwerkelijk in zetten
                    $rootScope.TL_4.push(data);
                    if (data.currentPosition != 0) {
                        $scope.removeLastPosTL(data.currentPosition, data);
                    }
                    data.currentPosition = 4;
                    break;
                }
            case 5:
                {
                    // Object uit de answers array als hij er nog in zit
                    var index = $scope.scientistsTimeLine.indexOf(data);
                    if (data.currentPosition == 5) {
                        return;
                    }
                    if (index != -1) {
                        $scope.scientistsTimeLine.splice(index, 1);
                    }
                    // Als er al een object in zit, dit verplaatsen naar answers array
                    if ($rootScope.TL_5.length >= 1) {
                        $rootScope.TL_5[0].currentPosition = 0;
                        $scope.scientistsTimeLine.push($rootScope.TL_5[0]);
                        $rootScope.TL_5 = [];
                    }
                    // Het object er daadwerkelijk in zetten
                    $rootScope.TL_5.push(data);
                    if (data.currentPosition != 0) {
                        $scope.removeLastPosTL(data.currentPosition, data);
                    }
                    data.currentPosition = 5;
                    break;
                }
            case 6:
                {
                    // Object uit de answers array als hij er nog in zit

                    var index = $scope.scientistsTimeLine.indexOf(data);
                    if (data.currentPosition == 6) {
                        return;
                    }
                    if (index != -1) {
                        $scope.scientistsTimeLine.splice(index, 1);
                    }
                    // Als er al een object in zit, dit verplaatsen naar answers array
                    if ($rootScope.TL_6.length >= 1) {
                        $rootScope.TL_6[0].currentPosition = 0;
                        $scope.scientistsTimeLine.push($rootScope.TL_6[0]);
                        $rootScope.TL_6 = [];
                    }
                    // Het object er daadwerkelijk in zetten
                    $rootScope.TL_6.push(data);
                    if (data.currentPosition != 0) {
                        $scope.removeLastPosTL(data.currentPosition, data);
                    }
                    data.currentPosition = 6;
                    break;
                }
        }
        console.log("Current at: ", data.currentPosition);
        $scope.checkAnswers_TL_1();

    }

    $scope.removeLastPosTL = function(target, data) {
        console.log("Removing something from the array: ", target, "this: ", data);
        switch (target) {
            // Vorige array leeg maken
            case 1:
                {
                    var index = $rootScope.TL_1.indexOf(data);
                    if (index != -1) {}
                    $rootScope.TL_1 = [];
                    break;
                }
            case 2:
                {
                    var index = $rootScope.TL_2.indexOf(data);
                    if (index != -1) {}
                    $rootScope.TL_2 = [];
                    break;
                }
            case 3:
                {
                    var index = $rootScope.TL_3.indexOf(data);
                    if (index != -1) {}
                    $rootScope.TL_3 = [];
                    break;
                }
            case 4:
                {
                    var index = $rootScope.TL_4.indexOf(data);
                    if (index != -1) {}
                    $rootScope.TL_4 = [];
                    break;
                }
            case 5:
                {
                    var index = $rootScope.TL_5.indexOf(data);
                    if (index != -1) {}
                    $rootScope.TL_5 = [];
                    break;
                }
            case 6:
                {
                    var index = $rootScope.TL_6.indexOf(data);
                    if (index != -1) {}
                    $rootScope.TL_6 = [];
                    break;
                }
        }
    }

    $scope.onDragTLComplete = function(data, happening) {
        console.log("New drag function", data, happening);
    }

    // Opdracht 2, deel 3





    $scope.gebeurtenisTimeLine = mainService.gebeurtenisTimeLine;



    $scope.onDropGebeurtenisComplete = function(target, data, happening) {
        console.log("New drop function", target, data, happening);
        switch (target) {
            case 1:
                {
                    // Object uit de answers array als hij er nog in zit
                    var index = $scope.gebeurtenisTimeLine.indexOf(data);
                    if (data.currentPosition == 1) {
                        return;
                    }
                    if (index != -1) {
                        $scope.gebeurtenisTimeLine.splice(index, 1);
                    }
                    // Als er al een object in zit, dit verplaatsen naar answers array
                    if ($rootScope.TL_1_2.length >= 1) {
                        $rootScope.TL_1_2[0].currentPosition = 0;
                        $scope.gebeurtenisTimeLine.push($rootScope.TL_1_2[0]);
                        $rootScope.TL_1_2 = [];
                    }
                    // Het object er daadwerkelijk in zetten
                    $rootScope.TL_1_2.push(data);
                    if (data.currentPosition != 0) {
                        $scope.removeLastPosGebeurtenis(data.currentPosition, data);
                    }
                    data.currentPosition = 1;
                    break;
                }




            case 2:
                {
                    // Object uit de answers array als hij er nog in zit
                    var index = $scope.gebeurtenisTimeLine.indexOf(data);
                    if (data.currentPosition == 2) {
                        return;
                    }
                    if (index != -1) {
                        $scope.gebeurtenisTimeLine.splice(index, 1);
                    }
                    // Als er al een object in zit, dit verplaatsen naar answers array
                    if ($rootScope.TL_2_2.length >= 1) {
                        $rootScope.TL_2_2[0].currentPosition = 0;
                        $scope.gebeurtenisTimeLine.push($rootScope.TL_2_2[0]);
                        $rootScope.TL_2_2 = [];
                    }
                    // Het object er daadwerkelijk in zetten
                    $rootScope.TL_2_2.push(data);
                    if (data.currentPosition != 0) {
                        $scope.removeLastPosGebeurtenis(data.currentPosition, data);
                    }
                    data.currentPosition = 2;
                    break;
                }


            case 3:
                {
                    // Object uit de answers array als hij er nog in zit
                    var index = $scope.gebeurtenisTimeLine.indexOf(data);
                    if (data.currentPosition == 3) {
                        return;
                    }
                    if (index != -1) {
                        $scope.gebeurtenisTimeLine.splice(index, 1);
                    }
                    // Als er al een object in zit, dit verplaatsen naar answers array
                    if ($rootScope.TL_3_2.length >= 1) {
                        $rootScope.TL_3_2[0].currentPosition = 0;
                        $scope.gebeurtenisTimeLine.push($rootScope.TL_3_2[0]);
                        $rootScope.TL_3_2 = [];
                    }
                    // Het object er daadwerkelijk in zetten
                    $rootScope.TL_3_2.push(data);
                    if (data.currentPosition != 0) {
                        $scope.removeLastPosGebeurtenis(data.currentPosition, data);
                    }
                    data.currentPosition = 3;
                    break;
                }


            case 4:
                {
                    // Object uit de answers array als hij er nog in zit
                    var index = $scope.gebeurtenisTimeLine.indexOf(data);
                    if (data.currentPosition == 4) {
                        return;
                    }
                    if (index != -1) {
                        $scope.gebeurtenisTimeLine.splice(index, 1);
                    }
                    // Als er al een object in zit, dit verplaatsen naar answers array
                    if ($rootScope.TL_4_2.length >= 1) {
                        $rootScope.TL_4_2[0].currentPosition = 0;
                        $scope.gebeurtenisTimeLine.push($rootScope.TL_4_2[0]);
                        $rootScope.TL_4_2 = [];
                    }
                    // Het object er daadwerkelijk in zetten
                    $rootScope.TL_4_2.push(data);
                    if (data.currentPosition != 0) {
                        $scope.removeLastPosGebeurtenis(data.currentPosition, data);
                    }
                    data.currentPosition = 4;
                    break;
                }


            case 5:
                {
                    // Object uit de answers array als hij er nog in zit
                    var index = $scope.gebeurtenisTimeLine.indexOf(data);
                    if (data.currentPosition == 5) {
                        return;
                    }
                    if (index != -1) {
                        $scope.gebeurtenisTimeLine.splice(index, 1);
                    }
                    // Als er al een object in zit, dit verplaatsen naar answers array
                    if ($rootScope.TL_5_2.length >= 1) {
                        $rootScope.TL_5_2[0].currentPosition = 0;
                        $scope.gebeurtenisTimeLine.push($rootScope.TL_5_2[0]);
                        $rootScope.TL_5_2 = [];
                    }
                    // Het object er daadwerkelijk in zetten
                    $rootScope.TL_5_2.push(data);
                    if (data.currentPosition != 0) {
                        $scope.removeLastPosGebeurtenis(data.currentPosition, data);
                    }
                    data.currentPosition = 5;
                    break;
                }

            case 6:
                {
                    // Object uit de answers array als hij er nog in zit
                    var index = $scope.gebeurtenisTimeLine.indexOf(data);
                    if (data.currentPosition == 6) {
                        return;
                    }
                    if (index != -1) {
                        $scope.gebeurtenisTimeLine.splice(index, 1);
                    }
                    // Als er al een object in zit, dit verplaatsen naar answers array
                    if ($rootScope.TL_6_2.length >= 1) {
                        $rootScope.TL_6_2[0].currentPosition = 0;
                        $scope.gebeurtenisTimeLine.push($rootScope.TL_6_2[0]);
                        $rootScope.TL_6_2 = [];
                    }
                    // Het object er daadwerkelijk in zetten
                    $rootScope.TL_6_2.push(data);
                    if (data.currentPosition != 0) {
                        $scope.removeLastPosGebeurtenis(data.currentPosition, data);
                    }
                    data.currentPosition = 6;
                    break;
                }


        }
        $scope.checkAnswers_TL_2();
        // if (data.currentPosition != 0) {
        //     $scope.removeLastPosGebeurtenis(data.currentPosition, data);
        // }
    }

    $scope.removeLastPosGebeurtenis = function(target, data) {
        switch (target) {
            // Vorige array leeg maken



            //  case 1: {
            //                 var index = $rootScope.TL_1.indexOf(data);
            //                 if (index != -1) {
            //                 }
            //                 $rootScope.TL_1 = [];
            //                 break;
            //             }


            case 1:
                {
                    var index = $rootScope.TL_1_2.indexOf(data);
                    if (index != -1) {}
                    $rootScope.TL_1_2 = [];
                    break;
                }
            case 2:
                {
                    var index = $rootScope.TL_2_2.indexOf(data);
                    if (index != -1) {}
                    $rootScope.TL_2_2 = [];
                    break;
                }
            case 3:
                {
                    var index = $rootScope.TL_3_2.indexOf(data);
                    if (index != -1) {}
                    $rootScope.TL_3_2 = [];
                    break;
                }
            case 4:
                {
                    var index = $rootScope.TL_4_2.indexOf(data);
                    if (index != -1) {}
                    $rootScope.TL_4_2 = [];
                    break;
                }
            case 5:
                {
                    var index = $rootScope.TL_5_2.indexOf(data);
                    if (index != -1) {}
                    $rootScope.TL_5_2 = [];
                    break;
                }
            case 6:
                {
                    var index = $rootScope.TL_6_2.indexOf(data);
                    if (index != -1) {}
                    $rootScope.TL_6_2 = [];
                    break;
                }
        }
    }

    $scope.checkAnswers_TL_1 = function() {

        $scope.counter_TL_1 = 0;
        $scope.correntAnswerCounter_TL_1 = 0;

        if ($rootScope.TL_1.length > 0) {
            $scope.counter_TL_1++;
            if ($rootScope.TL_1[0].title == "GALVANI") {
                $scope.correntAnswerCounter_TL_1++;
            }
        }
        if ($rootScope.TL_2.length > 0) {
            $scope.counter_TL_1++;
            if ($rootScope.TL_2[0].title == "VOLTA") {
                $scope.correntAnswerCounter_TL_1++;
            }
        }
        if ($rootScope.TL_3.length > 0) {
            $scope.counter_TL_1++;
            if ($rootScope.TL_3[0].title == "∅RSTED") {
                $scope.correntAnswerCounter_TL_1++;
            }
        }
        if ($rootScope.TL_4.length > 0) {
            $scope.counter_TL_1++;
            if ($rootScope.TL_4[0].title == "AMPÈRE") {
                $scope.correntAnswerCounter_TL_1++;
            }
        }
        if ($rootScope.TL_5.length > 0) {
            $scope.counter_TL_1++;
            if ($rootScope.TL_5[0].title == "FARADAY") {
                $scope.correntAnswerCounter_TL_1++;
            }
        }
        if ($rootScope.TL_6.length > 0) {
            $scope.counter_TL_1++;
            if ($rootScope.TL_6[0].title == "LORENTZ") {
                $scope.correntAnswerCounter_TL_1++;
            }
        }
        console.log("Aantal goede antwoorden:", $scope.correntAnswerCounter_TL_1);

        if ($scope.counter_TL_1 == 6) {
            $scope.continueCheck_TL1();
        }
    }

    $scope.checkAnswers_TL_2 = function() {

        $scope.counter_TL_2 = 0;
        $scope.correntAnswerCounter_TL_2 = 0;
        console.log("CHECK ANSWER 2")
        if ($rootScope.TL_1_2.length > 0) {
            $scope.counter_TL_2++;
            if ($rootScope.TL_1_2[0].title == "OPENING TEYLERS") {
                $scope.correntAnswerCounter_TL_2++;
            }
        }
        if ($rootScope.TL_2_2.length > 0) {
            $scope.counter_TL_2++;
            if ($rootScope.TL_2_2[0].title == "FRANSE REVOLUTIE") {
                $scope.correntAnswerCounter_TL_2++;
            }
        }
        if ($rootScope.TL_3_2.length > 0) {
            $scope.counter_TL_2++;
            if ($rootScope.TL_3_2[0].title == "SLAG BIJ WATERLOO") {
                $scope.correntAnswerCounter_TL_2++;
            }
        }
        if ($rootScope.TL_4_2.length > 0) {
            $scope.counter_TL_2++;
            if ($rootScope.TL_4_2[0].title == "ONAFHANKELIJK BELGIË") {
                $scope.correntAnswerCounter_TL_2++;
            }
        }
        if ($rootScope.TL_5_2.length > 0) {
            $scope.counter_TL_2++;
            if ($rootScope.TL_5_2[0].title == "NEDERLANDSE GRONDWET") {
                $scope.correntAnswerCounter_TL_2++;
            }
        }
        if ($rootScope.TL_6_2.length > 0) {
            $scope.counter_TL_2++;
            if ($rootScope.TL_6_2[0].title == "1e OLYMPISCHE SPELEN") {
                $scope.correntAnswerCounter_TL_2++;
            }
        }
        console.log("Aantal goede antwoorden:", $scope.counter_TL_2, $scope.correntAnswerCounter_TL_2);

        if ($scope.counter_TL_2 == 6) {
            $scope.continueCheck_TL2();
        }


    }

    $scope.Check = false;
    $rootScope.TL_1_Check = false;
    $scope.Check_C = false;
    $rootScope.TL_1_Check_C = false;
    $rootScope.TL_2_Check = false;
    $rootScope.TL_2_Check_C = false;


    $scope.continueCheck = function() {
        if ($scope.correntAnswerCounter == 6) {
            $scope.Check_C = true;
            $scope.Check = false;
        } else {
            $scope.Check = true;
            $scope.Check_C = false;
            setTimeout($scope.checkToFalse, 30);
        }
    }
    $scope.continueCheck_TL1 = function() {
        if ($scope.correntAnswerCounter_TL_1 == 6) {
            $rootScope.TL_1_Check_C = true;
            $rootScope.TL_1_Check = false;
        } else {
            $rootScope.TL_1_Check = true;
            $rootScope.TL_1_Check_C = false;
            setTimeout($scope.checkToFalse, 30);
        }
    }
    $scope.continueCheck_TL2 = function() {
        if ($scope.correntAnswerCounter_TL_2 == 6) {
            $rootScope.TL_2_Check_C = true;
            $rootScope.TL_2_Check = false;
        } else {
            $rootScope.TL_2_Check = true;
            $rootScope.TL_2_Check_C = false;
            setTimeout($scope.checkToFalse, 30);
        }
    }
    $scope.checkToFalse = function() {
        $rootScope.TL_1_Check = false;
        $rootScope.TL_2_Check = false;
    }




    // Algemene functionaliteit opdracht 2

    $scope._blobs = [1, 2, 3, 4];


    $scope.scientistData = mainService.option2data;

    console.log($scope.scientistData);
    // $scope._displayState = mainService.opdr2state;
    // $scope._displayState = '3';



    // UITZETTEN
    // $rootScope.opdr2StateR = '2';




    // $rootScope.opdr3StateR = '-1';
    // $rootScope.opdr4StateR = '-1';

    $scope.changeState = function(state) {
            // $scope._displayState = state;
            $rootScope.opdr2StateR = state;
            // console.log($scope._displayState, "is the new state")
        }
        // $rootScope.opdr2StateR = '0';
    $scope.goPrev = function(path) {
        $rootScope.opdr1StateR = '3';
        $rootScope.opdr2StateR = '-1';
        $rootScope.opdr3StateR = '-1';
        $rootScope.opdr4StateR = '-1';
        $location.path(path);
    };
    $scope.goNext = function(path) {
        $rootScope.opdr1StateR = '-1';
        $rootScope.opdr2StateR = '-1';
        $rootScope.opdr3StateR = '0';
        $rootScope.opdr4StateR = '-1';
        $location.path(path);
    };
}]);